import { Pipe, PipeTransform } from '@angular/core';

import { CatalogItem } from '../models/catalog-item';


@Pipe({
  name: 'categoryFilter'
})
export class CategoryFilterPipe implements PipeTransform {

  transform(items: CatalogItem[], categoryIds: string[]): CatalogItem[] {
    if (!items || !categoryIds) {
      return [];
    }
    if (categoryIds.length === 0) {
      return items;
    }

    return items.filter( (item: CatalogItem) => categoryIds.includes(item.categoryId));
  }

}
