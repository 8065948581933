import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Language } from '../models/language';
import { LanguageAccess } from '../access/language-access.service';


/**
 * Class providing management methods for languages.
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageManager {

  /**
   * The default constructor.
   */
  constructor(
    private languageAccess: LanguageAccess
  ) {
  }

  /**
   * Returns all Languages.
   *
   * @returns the found Languages, otherwise empty list
   */
  public getAllLanguages(): Observable<Language[]> {
    return this.languageAccess.getAllLanguages();
  }

  /**
   * Returns a language.
   *
   * @param languageId the language ID
   * @returns the found language, otherwise undefined
   */
  public getLanguage(languageId: string): Observable<Language | undefined> {
    return this.languageAccess.getLanguage(languageId);
  }

  /**
   * Adds a language.
   *
   * @param language the language to add
   * @returns the added language
   */
  public async addLanguage(language: Language): Promise<Language> {
    return this.languageAccess.addLanguage(language);
  }

  /**
   * Updates a language.
   *
   * @param language the language to update
   * @returns the updated language
   */
  public async updateLanguage(language: Language): Promise<Language> {
    return this.languageAccess.updateLanguage(language);
  }

  /**
   * Removes languages.
   *
   * @param languageIds the IDs of the languages to remove
   */
  public async removeLanguages(languageIds: string[]): Promise<void> {
    return this.languageAccess.removeLanguages(languageIds);
  }
}
