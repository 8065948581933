import { StorageFile } from './storage-file';


/**
 * An image file.
 */
export class StorageImage extends StorageFile {
  /** A caption that describes what is shown in the image. */
  public caption: string;
}
