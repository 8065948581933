import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-price-edit',
  templateUrl: './price-edit.component.html',
  styleUrls: ['./price-edit.component.scss'],
})
export class PriceEditComponent implements OnInit {

  @Input() header?: string;
  @Input() subHeader?: string;
  @Input() message?: string;
  @Input() next?: string;
  @Input() inputPlaceholder?: string;
  @Input() input: number | null;

  @ViewChild('inputView', {static: true}) inputView: IonInput;

  constructor(
    protected modalController: ModalController,
    protected translateService: TranslateService
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.inputView.setFocus().then();
      this.inputView.getInputElement().then((el) => el.select());
    }, 400);
  }

  public async closeModal(cancel: boolean, role: string): Promise<void> {
    await this.modalController.dismiss(cancel ? undefined : this.input, role);
  }
}
