import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { StorageImage } from '../models/storage-image';
import { StorageFile } from '../models/storage-file';
import { FirebaseStorageAccess } from '../access/firebase-storage-access.service';
import { CloudinaryStorageAccess } from '../access/cloudinary-storage-access.service';
import { ApiStorageAccess } from '../access/api-storage-access.service';


/**
 * Class providing management methods for the cloud storage.
 */
@Injectable({
  providedIn: 'root'
})
export class CloudStorageManager {

  /**
   * The default constructor.
   */
  constructor(
    private firebaseStorageAccess: FirebaseStorageAccess,
    private cloudinaryStorageAccess: CloudinaryStorageAccess,
    private apiStorageAccess: ApiStorageAccess
  ) {
  }

  /**
   * Adds a file to the cloud storage.
   *
   * @param file the file to upload
   * @returns the uploaded file
   */
  public async uploadFile(file: File): Promise<StorageFile> {
    return this.firebaseStorageAccess.uploadFile(file, environment.storage.rootFolder + '/' + environment.storage.userUploadFolder);
  }

  /**
   * Adds an image to the cloud storage.
   *
   * @param accountId the account ID
   * @param file the image to upload
   * @returns the uploaded image
   */
  public async uploadImage(accountId: string, file: File): Promise<StorageImage> {
    return await this.apiStorageAccess.uploadFile(accountId, file, environment.storage.rootFolder) as StorageImage;
  }

  /**
   * Adds an image to the cloud storage.
   *
   * @param accountId the account ID
   * @param dataUrl the file as a based64 encoded data URL to upload
   * @returns the uploaded image
   */
  public async uploadImageByDataUrl(accountId: string, dataUrl: string): Promise<StorageImage> {
    return await this.apiStorageAccess.uploadFileByDataUrl(accountId, dataUrl, environment.storage.rootFolder) as StorageImage;
  }

  /**
   * Removes files.
   *
   * @param storageFiles the StorageFiles to remove
   */
  public async removeFiles(storageFiles: StorageFile[]): Promise<void> {
    const toRemoveApiFiles: StorageFile[] = [];
    const toRemoveCloudinaryFiles: StorageFile[] = [];
    const toRemoveFirebaseFiles: StorageFile[] = [];

    for (const storageFile of storageFiles) {
      if (storageFile.refs && storageFile.refs.length > 0) {
        if (storageFile.refs[0].name === ApiStorageAccess.NAME) {
          toRemoveApiFiles.push(storageFile);
        } else if (storageFile.refs[0].name === CloudinaryStorageAccess.NAME) {
          toRemoveCloudinaryFiles.push(storageFile);
        } else if (storageFile.refs[0].name === FirebaseStorageAccess.NAME) {
          toRemoveFirebaseFiles.push(storageFile);
        }
      }
    }

    await this.apiStorageAccess.removeFiles(toRemoveApiFiles);
    await this.cloudinaryStorageAccess.removeFiles(toRemoveCloudinaryFiles);
    await this.firebaseStorageAccess.removeFiles(toRemoveFirebaseFiles);
  }
}
