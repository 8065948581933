import * as moment from 'moment-timezone';


/**
 * Utility class for Calendar operations.
 */
export class CalendarUtils {

  /**
   * Converts minutes to HH:mm.
   *
   * @param timeInMinutes time in minutes
   * @returns the time as HH:mm
   */
  static convertMinutesToTime(timeInMinutes: number): string {
    const hours = (timeInMinutes / 60);
    const rHours = Math.floor(hours);
    const minutes = (hours - rHours) * 60;
    const rMinutes = Math.round(minutes);

    return rHours.toString().padStart(2, '0') + ':' + rMinutes.toString().padStart(2, '0');
  }

  /**
   * Converts HH:mm to minutes.
   *
   * @param timeString time as HH:mm
   * @returns the time in minutes: HH*60 + mm
   */
  static convertTimeToMinutes(timeString: string): number {
    const time: string[] = timeString.split(':');

    return (+time[0] * 60) + (+time[1]);
  }

  /**
   * Changes (restaurant's local) time to user's local time.
   *
   * @param dateIsoString the time to change
   * @returns the changed time
   */
  static changeTimezoneToLocalTime(dateIsoString: string) {
    return moment(moment(dateIsoString).format(), 'YYYY-MM-DDTHH:mm').toISOString();
  }

  /**
   * Changes user's local time to (restaurant's local) time.
   *
   * @param dateIsoString the time to change
   * @param timezone the timezone to change to
   * @returns the changed time
   */
  static changeTimezoneOfLocalTime(dateIsoString: string, timezone: string) {
    return moment(moment(dateIsoString).format(), 'YYYY-MM-DDTHH:mm', timezone).toISOString();
  }

  /**
   * Converts HH:mm to a date as a ISO format string
   *
   * @param momentObject the moment the time is related to
   * @param timeString time as HH:mm
   * @returns the moment as a ISO format string
   */
  static convertTimeToMoment(momentObject: any, timeString: string): any {
    const time: string[] = timeString.split(':');

    const newMoment: any = momentObject.clone();
    newMoment.set('hour', +time[0]);
    newMoment.set('minute', +time[1]);
    newMoment.set('second', 0);
    newMoment.set('millisecond', 0);

    return newMoment;
  }
}
