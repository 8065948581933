import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';

import { User } from '../models/user';
import { SessionManager } from '../manager/session-manager.service';


/**
 * Guard restricts access to login route if user is already logged in.
 */
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  /**
   * The default constructor.
   */
  constructor(
    private navController: NavController,
    private sessionManager: SessionManager
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.sessionManager.isReady().pipe(
      switchMap(() => {
        return this.sessionManager.getCurrentUser();
      }),
      map((user: User | undefined) => {
        const isLoggedIn = user !== undefined;

        if (isLoggedIn) {
          this.navController.navigateRoot('/');
        }

        return !isLoggedIn;
      })
    );
  }
}
