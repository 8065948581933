import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { Location } from '../../shared/models/location';
import { OrderServiceInputFieldConstants } from '../../shared/models/order-service';
import { LocationManager } from '../../shared/manager/location-manager.service';
import { AnalyticsManager } from '../../shared/manager/analytics-manager.service';
import { BasePage } from '../../shared/base.page';


@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.page.html',
  styleUrls: ['./select-location.page.scss'],
})
export class SelectLocationPage extends BasePage implements OnInit {

  public searchControl: FormControl;
  public locations: Location[] = [];
  public isNearbySearch = true;

  constructor(
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager,
    private modalController: ModalController,
    private toastController: ToastController,
    private navController: NavController,
    private locationManager: LocationManager
  ) {
    super('select_location', translateService, alertController, loadingController, analyticsManager);

    this.searchControl = new FormControl();
  }

  async ngOnInit() {
    try {
      this.isNearbySearch = true;
      this.locations = await this.locationManager.findLocationsNearby();
    } catch (error) {
      console.log(error);
      await this.showToast(this.translateService.instant('SELECT_LOCATION.LOCALIZING_ERROR'));
      this.locations = await this.locationManager.findLocations(52.516257, 13.406129);
    }

    this.searchControl.valueChanges.pipe(debounceTime(200)).subscribe(async (search: string) => {
      try {
        if (search !== null && search.length > 0) {
          this.isNearbySearch = false;
          try {
            this.locations = await this.locationManager.findLocationsNearby(search, 30000);
          } catch (error) {
            console.log(error);
            this.locations = await this.locationManager.findLocations(52.516257, 13.406129, search, 10000000);
          }
        } else {
          this.isNearbySearch = true;
          try {
            this.locations = await this.locationManager.findLocationsNearby();
          } catch (error) {
            console.log(error);
            this.locations = await this.locationManager.findLocations(52.516257, 13.406129, undefined, 10000000);
          }
        }
      } catch (error) {
        console.log(error);
        alert('Error: ' + error);
      }
    });
  }

  public async dismiss() {
    await this.modalController.dismiss();
  }

  public async selectLocation(location: Location): Promise<void> {
    location.orderingDineInInputFields = [
      OrderServiceInputFieldConstants.LOCATION_PLACE_ID
    ].join(',');
    location.orderingPickupInputFields = [
      OrderServiceInputFieldConstants.FIRSTNAME,
      OrderServiceInputFieldConstants.LASTNAME,
      OrderServiceInputFieldConstants.EMAIL,
      OrderServiceInputFieldConstants.PHONE_NUMBER
    ].join(',');
    location.orderingDeliveryInputFields = [
      OrderServiceInputFieldConstants.FIRSTNAME,
      OrderServiceInputFieldConstants.LASTNAME,
      OrderServiceInputFieldConstants.EMAIL,
      OrderServiceInputFieldConstants.PHONE_NUMBER,
      OrderServiceInputFieldConstants.COMPANY_NAME,
      OrderServiceInputFieldConstants.ADDRESS,
      OrderServiceInputFieldConstants.FLOOR
    ].join(',');

    await this.modalController.dismiss(location);
  }

  private async showToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message,
      duration: 4000
    });
    toast.present();
  }
}
