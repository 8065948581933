import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { PricePlan } from '../models/price-plan';
import { PlanSubscription } from '../models/plan-subscription';


/**
 * Class providing access methods for pricing plans.
 */
@Injectable({
  providedIn: 'root'
})
export class PlanAccess {

  private pricePlanCollection: AngularFirestoreCollection<PricePlan>;
  private planSubscriptionCollection: AngularFirestoreCollection<PlanSubscription>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore,
  ) {
    this.pricePlanCollection = afs.collection<PricePlan>('pricePlans');
    this.planSubscriptionCollection = afs.collection<PlanSubscription>('planSubscriptions');
  }

  /**
   * Add new PricePlan to cloud.
   *
   * @param pricePlan the PricePlan object to add
   * @return the id of the new PricePlan
   */
  public async addPricePlan(pricePlan: PricePlan): Promise<string> {
    if (!pricePlan.id) {
      pricePlan.id = this.afs.createId();
      pricePlan.createdAt = new Date().toISOString();
    }
    pricePlan.updatedAt = new Date().toISOString();

    await this.pricePlanCollection.doc(pricePlan.id).set(classToPlain(pricePlan) as PricePlan, { merge: true });

    return pricePlan.id;
  }

  /**
   * Returns all PricePlans.
   *
   * @returns the found PricePlans, otherwise empty list
   */
  public getAllPricePlans(): Observable<PricePlan[]> {
    return this.afs.collection<PricePlan>('pricePlans')
      .valueChanges().pipe(
        map((pricePlansJson) => {
          return pricePlansJson as PricePlan[]; // plainToClass(PricePlan, pricePlansJson as object[]);
        })
      );
  }

  /**
   * Returns the PricePlan specified by the ID.
   *
   * @param pricePlanId the PricePlan ID
   * @returns the found PricePlan, otherwise undefined
   */
  public getPricePlan(pricePlanId: string): Observable<PricePlan> {
    return this.pricePlanCollection.doc(pricePlanId).valueChanges().pipe(
      map((pricePlanJson) => {
        return pricePlanJson as PricePlan; // plainToClass(PricePlan, pricePlanJson);
      })
    );
  }

  /**
   * Removes PricePlans.
   *
   * @param pricePlanIds the IDs of the PricePlans to remove
   */
  public async removePricePlans(pricePlanIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const pricePlanId of pricePlanIds) {
      batch.delete(this.pricePlanCollection.doc(pricePlanId).ref);
    }

    await batch.commit();
  }

  /**
   * Changes visibility of PricePlans.
   *
   * @param pricePlanIds the IDs of the PricePlans to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfPricePlans(pricePlanIds: string[], visible: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const pricePlanId of pricePlanIds) {
      batch.update(this.pricePlanCollection.doc(pricePlanId).ref, { visibility: visible });
    }

    await batch.commit();
  }

  /**
   * Add new PlanSubscription to cloud.
   *
   * @param planSubscription the PlanSubscription object to add
   * @return the id of the new PlanSubscription
   */
  public async addPlanSubscription(planSubscription: PlanSubscription): Promise<string> {
    if (!planSubscription.id) {
      planSubscription.id = this.afs.createId();
      planSubscription.createdAt = new Date().toISOString();
    }
    planSubscription.updatedAt = new Date().toISOString();

    await this.planSubscriptionCollection.doc(planSubscription.id).set(classToPlain(planSubscription) as PlanSubscription, { merge: true });

    return planSubscription.id;
  }

  /**
   * Returns all PlanSubscriptions of an account.
   *
   * @param accountId the ID of the account
   * @returns the found PlanSubscriptions, otherwise empty list
   */
  public getAllPlanSubscriptions(accountId: string): Observable<PlanSubscription[]> {
    return this.afs.collection<PlanSubscription>('planSubscriptions', ref => ref.where('accountId', '==', accountId).orderBy('createdAt'))
      .valueChanges().pipe(
        map((planSubscriptionsJson) => {
          return planSubscriptionsJson as PlanSubscription[]; // plainToClass(PlanSubscription, planSubscriptionsJson as object[]);
        })
      );
  }

  /**
   * Returns the PlanSubscription specified by the ID.
   *
   * @param planSubscriptionId the PlanSubscription ID
   * @returns the found PlanSubscription, otherwise undefined
   */
  public getPlanSubscription(planSubscriptionId: string): Observable<PlanSubscription> {
    return this.planSubscriptionCollection.doc(planSubscriptionId).valueChanges().pipe(
      map((planSubscriptionJson) => {
        return planSubscriptionJson as PlanSubscription; // plainToClass(PlanSubscription, planSubscriptionJson);
      })
    );
  }

  /**
   * Removes PlanSubscriptions.
   *
   * @param planSubscriptionIds the IDs of the PlanSubscriptions to remove
   */
  public async removePlanSubscriptions(planSubscriptionIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const planSubscriptionId of planSubscriptionIds) {
      batch.delete(this.planSubscriptionCollection.doc(planSubscriptionId).ref);
    }

    await batch.commit();
  }
}
