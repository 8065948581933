import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { combineLatest, Observable, timer } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';


/**
 * Show time difference.
 */
@Pipe({
  name: 'timeDiff'
})
export class TimeDiffPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {
  }

  transform(value: string | Date): Observable<string> {
    return combineLatest([
      this.translateService.get([
        'PIPE_TIME_DIFF.JUST_NOW',
        'PIPE_TIME_DIFF.IN_MINUTES',
        'PIPE_TIME_DIFF.IN_HOURS',
        'PIPE_TIME_DIFF.IN_DAYS'
      ]),
      timer(0, 2000)
    ]).pipe(
      map((results: (string[] | number)[]) => {
        const strings: string[] = results[0] as string[];
        let text = '';

        const diff: number = Date.now() - new Date(value).getTime();
        const diffInMinutes: number = diff / 60000;
        const diffInHours: number = diffInMinutes / 60;
        const diffInDays: number = diffInHours / 24;

        if (diff < 60000) {
          text = strings['PIPE_TIME_DIFF.JUST_NOW'];
        } else if (diffInMinutes < 60) {
          text = strings['PIPE_TIME_DIFF.IN_MINUTES'].replace('{{count}}', Math.floor(diffInMinutes) + '');
        } else if (diffInHours < 24) {
          text = strings['PIPE_TIME_DIFF.IN_HOURS'].replace('{{count}}', Math.floor(diffInHours) + '');
        } else {
          text = strings['PIPE_TIME_DIFF.IN_DAYS'].replace('{{count}}', Math.floor(diffInDays) + '');
        }

        return text;
      })
    );
  }
}
