import { Pipe, PipeTransform } from '@angular/core';

import { CalendarUtils } from '../utils/calendar-utils';


/**
 * Show minutes in format HH:mm.
 */
@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  constructor(
  ) {
  }

  transform(value: number): string {
    return CalendarUtils.convertMinutesToTime(value);
  }
}
