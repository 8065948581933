import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Reservation } from '../models/reservation';
import { ReservationAccess } from '../access/reservation-access.service';


/**
 * Class providing management methods for reservations.
 */
@Injectable({
  providedIn: 'root'
})
export class ReservationManager {

  /**
   * The default constructor.
   */
  constructor(
    private reservationAccess: ReservationAccess
  ) {
  }

  /**
   * Add new Reservation to cloud.
   *
   * @param reservation the Reservation object to add
   * @return the id of the new Reservation
   */
  public async addReservation(reservation: Reservation): Promise<string> {
    return this.reservationAccess.addReservation(reservation);
  }

  /**
   * Returns all Reservations of a Location.
   *
   * @param locationId the ID of the Location
   * @param fromTime the from date in RFC 3339 format
   * @param toTime the to date in RFC 3339 format
   * @returns the found Reservations, otherwise empty list
   */
  public getAllReservations(locationId?: string, fromTime?: string, toTime?: string): Observable<Reservation[]> {
    return this.reservationAccess.getAllReservations(locationId, fromTime, toTime);
  }

  /**
   * Returns all Reservations of a Customer.
   *
   * @param customerId the ID of the Customer
   * @returns the found Reservations, otherwise empty list
   */
  public getReservationsOfCustomer(customerId: string): Observable<Reservation[]> {
    return this.reservationAccess.getReservationsOfCustomer(customerId);
  }

  /**
   * Returns the Reservation specified by the ID.
   *
   * @param reservationId the Reservation ID
   * @returns the found Reservation, otherwise undefined
   */
  public getReservation(reservationId: string): Observable<Reservation> {
    return this.reservationAccess.getReservation(reservationId);
  }

  /**
   * Returns the Reservation specified by the cancellation code.
   *
   * @param cancellationCode the cancellation code
   * @returns the found Reservation, otherwise undefined
   */
  public getReservationByCancellationCode(cancellationCode: string): Observable<Reservation> {
    return this.reservationAccess.getReservationByCancellationCode(cancellationCode);
  }

  /**
   * Removes Reservations.
   *
   * @param reservationIds the IDs of the Reservations to remove
   */
  public async removeReservations(reservationIds: string[]): Promise<void> {
    return this.reservationAccess.removeReservations(reservationIds);
  }

  /**
   * Changes status of Reservations.
   *
   * @param reservationIds the IDs of the Reservations to change
   * @param status possible values are 'request', 'confirmed', 'cancelled'
   */
  public async setStatusOfReservations(reservationIds: string[], status: string): Promise<void> {
    return this.reservationAccess.setStatusOfReservations(reservationIds, status);
  }
}
