import { Pipe, PipeTransform } from '@angular/core';

import { CatalogItem } from '../models/catalog-item';


@Pipe({
  name: 'locationFilter'
})
export class LocationFilterPipe implements PipeTransform {

  transform(items: CatalogItem[], locationIds: string[]): CatalogItem[] {
    if (!items || !locationIds) {
      return [];
    }
    if (locationIds.length === 0) {
      return items;
    }

    return items.filter( (item: CatalogItem) => {
      let keep: boolean = item.presentAtAllLocations !== false;

      if (!keep && item.presentAtLocationIds) {
        for (const id of locationIds) {
          if (item.presentAtLocationIds.includes(id)) {
            keep = true;
            break;
          }
        }
      }

      return keep;
    });
  }

}
