
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  production: false,
  appId: 'menu_manager_business_dev',
  customerAppId: 'menu_manager_dev',
  app: {
    version: '1.0.0-1',
    rootUrl: 'http://localhost:8101',
    defaultLang: 'de',
    pin: '3968',
    currentTermsVersion: '2023-05-01T00:00:00.000Z'
  },
  menuApp: {
    locationLink: 'http://localhost:8100/?restaurant_id={{locationLinkName}}',
    locationDomainLink: 'https://{{locationDomain}}.de',
    reservationLink: 'http://localhost:8100/?id={{locationLinkName}}',
    locationPlaceLink: 'http://localhost:8100/?place_id={{placeId}}',
    locationDomainPlaceLink: 'https://{{locationDomain}}.de/p/{{placeId}}',
    defaultTimezone: 'Europe/Berlin'
  },
  firebaseConfig: {
    apiKey: "AIzaSyBS25k1211ov8P1U_ZV0VwH8Tukvmw0xGA",
    authDomain: "menu-manager-dev.firebaseapp.com",
    projectId: "menu-manager-dev",
    storageBucket: "menu-manager-dev.appspot.com",
    messagingSenderId: "699829065192",
    appId: "1:699829065192:web:570e0fc54244762f00005d"
  },
  auth: {
    useEmailVerification: true
  },
  signup: {
    defaultLang: 'de',
    defaultPricePlanId: 'menu_free'
  },
  dish: {
    predefinedTags: 'halal,koscher,vegan,vegetarisch,bio'
  },
  storage: {
    rootFolder: 'business-dev',
    userUploadFolder: 'user-upload'
  },
  firebaseStorageConfig: {
    imageRefName: 'firebase'
  },
  cloudinaryConfig: {
    cloudName: 'dhisro6k4',
    unsignedUploadPreset: 'itk7dken',
    imageRefName: 'cloudinary'
  },
  apiStorageConfig: {
    imageRefName: 'api',
    baseUrl: 'https://menu-manager.de/c/menu-dev/upload/'
  },
  firebaseEndpoint: {
    invoiceNumber: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/getNextInvoiceNumber',
    orderConfirmationReminder: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/sendConfirmationReminder',
    fileUpload: 'https://europe-west3-menu-manager-dev.cloudfunctions.net/api/file'
  },
  accountBusinessTypeUi: {
    RESTAURANT: {
      id: 'RESTAURANT', langKeyPrefix: '', hidePreferredTime: false, hideOrdering: false
    },
    RESTAURANT_MENU: {
      id: 'RESTAURANT_MENU', langKeyPrefix: '', hidePreferredTime: false, hideOrdering: true
    },
    SHOP: {
      id: 'SHOP', langKeyPrefix: 'SHOP.', hidePreferredTime: false, hideOrdering: false
    },
    ACCOMMODATION: {
      id: 'ACCOMMODATION', langKeyPrefix: 'ACCOMMODATION.', hidePreferredTime: false, hideOrdering: false
    },
    ACCOMMODATION_MANAGED: {
      id: 'ACCOMMODATION_MANAGED', langKeyPrefix: 'MANAGED.ACCOMMODATION.', hidePreferredTime: true, hideOrdering: false
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
