import { Money } from './money';
import { Address } from './address';


/**
 * The types of business to support.
 */
export enum AccountBusinessTypeConstants {
  /** Restaurant services. */
  RESTAURANT = 'RESTAURANT',
  /** Menu services. */
  RESTAURANT_MENU = 'RESTAURANT_MENU',
  /** Shop services. */
  SHOP = 'SHOP',
  /** Accommodation services. */
  ACCOMMODATION = 'ACCOMMODATION',
  /** Managed accommodation services. */
  ACCOMMODATION_MANAGED = 'ACCOMMODATION_MANAGED'
}

/**
 * Represents a company account.
 */
export class Account {

  /** The unique ID of this object. */
  public id: string;

  /** The account's name. */
  public name: string;

  /** The account's type. */
  public businessType: AccountBusinessTypeConstants = AccountBusinessTypeConstants.RESTAURANT;

  /** Identifies the AccountImages attached to this Account. */
  public imageIds?: string[];

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;

  /**
   * The company's website, as set by the account owner in the Square dashboard.
   * Default: none; only exists if explicitly set.
   */
  public websiteUrl?: string;

  /** The default language code in ISO-639-1 format. */
  public defaultLang: string;

  /**
   * The currency used for all items of this account, specified in ISO 4217 format. For example, the currency for a location
   * processing transactions in the United States is 'USD'. See Currency for possible values.
   */
  public defaultCurrency: string;

  /** The unique customer number. */
  public customerNumber?: string;

  /**
   * References to external services like payment service providers for this account receiving payments.
   */
  public paymentRecipient: { name: string, ref: string }[] = [];
}
