import { Injectable } from '@angular/core';
import { first, flatMap, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection, DocumentSnapshot, Query } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { classToPlain } from 'class-transformer';

import { Location } from '../models/location';
import { Catalog } from '../models/catalog';
import { CatalogMenu } from '../models/catalog-menu';
import { CatalogItem } from '../models/catalog-item';
import { CatalogImage } from '../models/catalog-image';
import { CatalogTax } from '../models/catalog-tax';
import { CatalogAllergen } from '../models/catalog-allergen';
import { CatalogAdditive } from '../models/catalog-additive';
import { CatalogDiet } from '../models/catalog-diet';
import { CatalogModifierList } from '../models/catalog-modifier-list';
import { CatalogModifier } from '../models/catalog-modifier';
import { CatalogDiscount } from '../models/catalog-discount';
import { CatalogDiscountCode } from '../models/catalog-discount-code';
import { CatalogPricingRule } from '../models/catalog-pricing-rule';
import { CatalogLabel } from '../models/catalog-label';
import { LocationAccess } from './location-access.service';
import { ArrayUtils } from '../utils/array-utils';


/**
 * Class providing access methods for Catalog objects.
 */
@Injectable({
  providedIn: 'root'
})
export class CatalogAccess {

  private catalogAllergenCollection: AngularFirestoreCollection<CatalogAllergen>;
  private catalogAdditiveCollection: AngularFirestoreCollection<CatalogAdditive>;
  private catalogDietCollection: AngularFirestoreCollection<CatalogDiet>;
  private catalogCollection: AngularFirestoreCollection<Catalog>;
  private catalogMenuCollection: AngularFirestoreCollection<CatalogMenu>;
  private catalogItemCollection: AngularFirestoreCollection<CatalogItem>;
  private catalogImageCollection: AngularFirestoreCollection<CatalogImage>;
  private catalogTaxCollection: AngularFirestoreCollection<CatalogTax>;
  private catalogModifierListCollection: AngularFirestoreCollection<CatalogModifierList>;
  private catalogModifierCollection: AngularFirestoreCollection<CatalogModifier>;
  private catalogDiscountCollection: AngularFirestoreCollection<CatalogDiscount>;
  private catalogPricingRuleCollection: AngularFirestoreCollection<CatalogPricingRule>;
  private catalogDiscountCodeCollection: AngularFirestoreCollection<CatalogDiscountCode>;
  private catalogLabelCollection: AngularFirestoreCollection<CatalogLabel>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore,
    private locationAccess: LocationAccess
  ) {
    this.catalogAllergenCollection = afs.collection<CatalogAllergen>('catalogAllergens');
    this.catalogAdditiveCollection = afs.collection<CatalogAdditive>('catalogAdditives');
    this.catalogDietCollection = afs.collection<CatalogDiet>('catalogDiets');
    this.catalogCollection = afs.collection<Catalog>('catalogs');
    this.catalogMenuCollection = afs.collection<CatalogMenu>('catalogMenus');
    this.catalogItemCollection = afs.collection<CatalogItem>('catalogItems');
    this.catalogImageCollection = afs.collection<CatalogImage>('catalogImages');
    this.catalogTaxCollection = afs.collection<CatalogTax>('catalogTaxes');
    this.catalogModifierListCollection = afs.collection<CatalogModifierList>('catalogModifierLists');
    this.catalogModifierCollection = afs.collection<CatalogModifier>('catalogModifiers');
    this.catalogDiscountCollection = afs.collection<CatalogDiscount>('catalogDiscounts');
    this.catalogPricingRuleCollection = afs.collection<CatalogPricingRule>('catalogPricingRules');
    this.catalogDiscountCodeCollection = afs.collection<CatalogDiscountCode>('catalogDiscountCodes');
    this.catalogLabelCollection = afs.collection<CatalogLabel>('catalogLabels');
  }

  /**
   * Add new CatalogAllergen to cloud.
   *
   * @param catalogAllergen the CatalogAllergen object to add
   * @return the id of the new CatalogAllergen
   */
  public async addCatalogAllergen(catalogAllergen: CatalogAllergen): Promise<string> {
    if (!catalogAllergen.id) {
      catalogAllergen.id = this.afs.createId();
    }

    await this.catalogAllergenCollection.doc(catalogAllergen.id).set(classToPlain(catalogAllergen) as CatalogAllergen, { merge: true });

    return catalogAllergen.id;
  }

  /**
   * Returns all CatalogAllergens.
   *
   * @returns the found CatalogAllergens, otherwise empty list
   */
  public getAllCatalogAllergens(): Observable<CatalogAllergen[]> {
    return this.catalogAllergenCollection.valueChanges().pipe(
      map((catalogAllergensJson) => {
        return catalogAllergensJson as CatalogAllergen[]; // plainToClass(CatalogAllergen, catalogAllergensJson as object[]);
      })
    );
  }

  /**
   * Returns the CatalogAllergen specified by the ID.
   *
   * @param catalogAllergenId the CatalogAllergen ID
   * @returns the found CatalogAllergen, otherwise undefined
   */
  public getCatalogAllergen(catalogAllergenId: string): Observable<CatalogAllergen> {
    return this.catalogAllergenCollection.doc(catalogAllergenId).valueChanges().pipe(
      map((catalogAllergenJson) => {
        return catalogAllergenJson as CatalogAllergen; // plainToClass(CatalogAllergen, catalogAllergenJson);
      })
    );
  }

  /**
   * Removes CatalogAllergens.
   *
   * @param catalogAllergenIds the IDs of the CatalogAllergens to remove
   */
  public async removeCatalogAllergens(catalogAllergenIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogAllergenId of catalogAllergenIds) {
      batch.delete(this.catalogAllergenCollection.doc(catalogAllergenId).ref);

      // remove references in CatalogItems
      await this.afs.collection<CatalogItem>('catalogItems', ref => ref.where('allergenIds', 'array-contains', catalogAllergenId))
        .valueChanges().pipe(
          first(),
          map((catalogItemsJson) => {
            return catalogItemsJson as CatalogItem[]; // plainToClass(CatalogItem, catalogItemsJson as object[]);
          }),
          switchMap((catalogItems: CatalogItem[]) => {
            catalogItems.forEach((item: CatalogItem) => {
              const index: number = item.allergenIds.findIndex((id: string) => id === catalogAllergenId);
              item.allergenIds.splice(index, 1);
            });

            return this.addCatalogItems(catalogItems);
          })
        ).toPromise();
    }

    await batch.commit();
  }

  /**
   * Add new CatalogAdditive to cloud.
   *
   * @param catalogAdditive the CatalogAdditive object to add
   * @return the id of the new CatalogAdditive
   */
  public async addCatalogAdditive(catalogAdditive: CatalogAdditive): Promise<string> {
    if (!catalogAdditive.id) {
      catalogAdditive.id = this.afs.createId();
    }

    await this.catalogAdditiveCollection.doc(catalogAdditive.id).set(classToPlain(catalogAdditive) as CatalogAdditive, { merge: true });

    return catalogAdditive.id;
  }

  /**
   * Returns all CatalogAdditives.
   *
   * @returns the found CatalogAdditives, otherwise empty list
   */
  public getAllCatalogAdditives(): Observable<CatalogAdditive[]> {
    return this.catalogAdditiveCollection.valueChanges().pipe(
      map((catalogAdditivesJson) => {
        return catalogAdditivesJson as CatalogAdditive[]; // plainToClass(CatalogAdditive, catalogAdditivesJson as object[]);
      })
    );
  }

  /**
   * Returns the CatalogAdditive specified by the ID.
   *
   * @param catalogAdditiveId the CatalogAdditive ID
   * @returns the found CatalogAdditive, otherwise undefined
   */
  public getCatalogAdditive(catalogAdditiveId: string): Observable<CatalogAdditive> {
    return this.catalogAdditiveCollection.doc(catalogAdditiveId).valueChanges().pipe(
      map((catalogAdditiveJson) => {
        return catalogAdditiveJson as CatalogAdditive; // plainToClass(CatalogAdditive, catalogAdditiveJson);
      })
    );
  }

  /**
   * Removes CatalogAdditives.
   *
   * @param catalogAdditiveIds the IDs of the CatalogAdditives to remove
   */
  public async removeCatalogAdditives(catalogAdditiveIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogAdditiveId of catalogAdditiveIds) {
      batch.delete(this.catalogAdditiveCollection.doc(catalogAdditiveId).ref);

      // remove references in CatalogItems
      await this.afs.collection<CatalogItem>('catalogItems', ref => ref.where('additiveIds', 'array-contains', catalogAdditiveId))
        .valueChanges().pipe(
          first(),
          map((catalogItemsJson) => {
            return catalogItemsJson as CatalogItem[]; // plainToClass(CatalogItem, catalogItemsJson as object[]);
          }),
          switchMap((catalogItems: CatalogItem[]) => {
            catalogItems.forEach((item: CatalogItem) => {
              const index: number = item.additiveIds.findIndex((id: string) => id === catalogAdditiveId);
              item.additiveIds.splice(index, 1);
            });

            return this.addCatalogItems(catalogItems);
          })
        ).toPromise();
    }

    await batch.commit();
  }

  /**
   * Add new CatalogDiet to cloud.
   *
   * @param catalogDiet the CatalogDiet object to add
   * @return the id of the new CatalogDiet
   */
  public async addCatalogDiet(catalogDiet: CatalogDiet): Promise<string> {
    if (!catalogDiet.id) {
      catalogDiet.id = this.afs.createId();
    }

    await this.catalogDietCollection.doc(catalogDiet.id).set(classToPlain(catalogDiet) as CatalogDiet, { merge: true });

    return catalogDiet.id;
  }

  /**
   * Returns all CatalogDiets.
   *
   * @returns the found CatalogDiets, otherwise empty list
   */
  public getAllCatalogDiets(): Observable<CatalogDiet[]> {
    return this.catalogDietCollection.valueChanges().pipe(
      map((catalogDietsJson) => {
        return catalogDietsJson as CatalogDiet[]; // plainToClass(CatalogDiet, catalogDietsJson as object[]);
      })
    );
  }

  /**
   * Returns the CatalogDiet specified by the ID.
   *
   * @param catalogDietId the CatalogDiet ID
   * @returns the found CatalogDiet, otherwise undefined
   */
  public getCatalogDiet(catalogDietId: string): Observable<CatalogDiet> {
    return this.catalogDietCollection.doc(catalogDietId).valueChanges().pipe(
      map((catalogDietJson) => {
        return catalogDietJson as CatalogDiet; // plainToClass(CatalogDiet, catalogDietJson);
      })
    );
  }

  /**
   * Removes CatalogDiets.
   *
   * @param catalogDietIds the IDs of the CatalogDiets to remove
   */
  public async removeCatalogDiets(catalogDietIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogDietId of catalogDietIds) {
      batch.delete(this.catalogDietCollection.doc(catalogDietId).ref);

      // remove references in CatalogItems
      await this.afs.collection<CatalogItem>('catalogItems', ref => ref.where('dietIds', 'array-contains', catalogDietId))
        .valueChanges().pipe(
          first(),
          map((catalogItemsJson) => {
            return catalogItemsJson as CatalogItem[]; // plainToClass(CatalogItem, catalogItemsJson as object[]);
          }),
          switchMap((catalogItems: CatalogItem[]) => {
            catalogItems.forEach((item: CatalogItem) => {
              const index: number = item.dietIds.findIndex((id: string) => id === catalogDietId);
              item.dietIds.splice(index, 1);
            });

            return this.addCatalogItems(catalogItems);
          })
        ).toPromise();
    }

    await batch.commit();
  }

  /**
   * Add new CatalogMenu to cloud.
   *
   * @param catalogMenu the CatalogMenu object to add
   * @return the id of the new CatalogMenu
   */
  public async addCatalogMenu(catalogMenu: CatalogMenu): Promise<string> {
    const batch = this.afs.firestore.batch();

    if (!catalogMenu.id) {
      catalogMenu.id = this.afs.createId();
    }

    if (catalogMenu.categories) {
      let ordinal = 1;
      for (const category of catalogMenu.categories) {
        category.ordinal = ordinal++;
        if (!category.id) {
          category.id = this.afs.createId();
        }
      }
    }

    batch.set(this.catalogMenuCollection.doc(catalogMenu.id).ref, classToPlain(catalogMenu) as CatalogMenu, { merge: true });
    batch.set(this.catalogCollection.doc(catalogMenu.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

    await batch.commit();

    return catalogMenu.id;
  }

  /**
   * Returns all CatalogMenus of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogMenus, otherwise empty list
   */
  public getAllCatalogMenus(accountId: string): Observable<CatalogMenu[]> {
    return this.afs.collection<CatalogMenu>('catalogMenus', ref => ref.where('accountId', '==', accountId).orderBy('ordinal'))
      .valueChanges().pipe(
        map((catalogMenusJson) => {
          return catalogMenusJson as CatalogMenu[]; // plainToClass(CatalogMenu, catalogMenusJson as object[]);
        })
      );
  }

  /**
   * Returns the CatalogMenu specified by the ID.
   *
   * @param catalogMenuId the CatalogMenu ID
   * @returns the found CatalogMenu, otherwise undefined
   */
  public getCatalogMenu(catalogMenuId: string): Observable<CatalogMenu | undefined> {
    return this.catalogMenuCollection.doc(catalogMenuId).valueChanges().pipe(
      map((catalogMenuJson) => {
        return catalogMenuJson as CatalogMenu; // plainToClass(CatalogMenu, catalogMenuJson);
      })
    );
  }

  /**
   * Removes CatalogMenus.
   *
   * @param catalogMenuIds the IDs of the CatalogMenus to remove
   */
  public async removeCatalogMenus(catalogMenuIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    const imageIdsToRemove: string[] = [];
    for (const catalogMenuId of catalogMenuIds) {
      const catalogMenu: CatalogMenu | undefined = await this.getCatalogMenu(catalogMenuId).pipe(first()).toPromise();
      if (catalogMenu && catalogMenu.imageIds) {
        imageIdsToRemove.push(...catalogMenu.imageIds);
      }

      batch.delete(this.catalogMenuCollection.doc(catalogMenuId).ref);
      batch.set(this.catalogCollection.doc(catalogMenu.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });
    }
    await this.removeCatalogImages(imageIdsToRemove);

    await batch.commit();
  }

  /**
   * Changes visibility of CatalogMenus.
   *
   * @param accountId the ID of the Account the CatalogMenus belong to
   * @param catalogMenuIds the IDs of the CatalogMenus to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfCatalogMenus(accountId: string, catalogMenuIds: string[], visible: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogMenuId of catalogMenuIds) {
      batch.update(this.catalogMenuCollection.doc(catalogMenuId).ref, { visibility: visible });
      batch.set(this.catalogCollection.doc(accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });
    }

    await batch.commit();
  }

  /**
   * Saves CatalogMenu order.
   *
   * @param catalogMenus the CatalogMenus in the order to save
   */
  public async saveCatalogMenuOrder(catalogMenus: CatalogMenu[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    let ordinal = 1;
    for (const catalogMenu of catalogMenus) {
      batch.update(this.catalogMenuCollection.doc(catalogMenu.id).ref, { ordinal });
      batch.set(this.catalogCollection.doc(catalogMenu.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });
      ordinal++;
    }

    await batch.commit();
  }

  /**
   * Adds new CatalogItems to cloud.
   *
   * @param catalogItems the CatalogItem objects to add
   * @return the IDs of the new CatalogItems
   */
  public async addCatalogItems(catalogItems: CatalogItem[]): Promise<string[]> {
    const ids: string[] = [];

    // split into chunks because of Firebase's restriction of 500 queries in one batch
    const catalogItemChunks: CatalogItem[][] = ArrayUtils.splitArrayIntoChunks(catalogItems, 200);
    for (const chunk of catalogItemChunks) {
      const batch = this.afs.firestore.batch();

      for (const catalogItem of chunk) {
        if (!catalogItem.id) {
          catalogItem.id = this.afs.createId();
          catalogItem.createdAt = new Date().toISOString();
        }
        catalogItem.updatedAt = new Date().toISOString();

        batch.set(this.catalogItemCollection.doc(catalogItem.id).ref, classToPlain(catalogItem) as CatalogItem, { merge: true });
        batch.set(this.catalogCollection.doc(catalogItem.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

        ids.push(catalogItem.id);
      }
      await batch.commit();
    }

    return ids;
  }

  /**
   * Moves CatalogItems to another CatalogCategory.
   *
   * @param catalogItems the CatalogItem objects to move
   * @param targetCategoryId the target category the items are moved to
   */
  public async moveCatalogItems(catalogItems: CatalogItem[], targetCategoryId: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogItem of catalogItems) {
      batch.update(this.catalogItemCollection.doc(catalogItem.id).ref, { categoryId: targetCategoryId });
      batch.set(this.catalogCollection.doc(catalogItem.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });
    }

    await batch.commit();
  }

  /**
   * Returns all CatalogItems of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogItems, otherwise empty list
   */
  public getAllCatalogItems(accountId: string): Observable<CatalogItem[]> {
    return this.afs.collection<CatalogItem>('catalogItems', ref => ref.where('accountId', '==', accountId).orderBy('ordinal'))
      .valueChanges().pipe(
        map((catalogItemsJson) => {
          return catalogItemsJson as CatalogItem[]; // plainToClass(CatalogItem, catalogItemsJson as object[]);
        })
      );
  }

  /**
   * Returns the CatalogItem specified by the ID.
   *
   * @param catalogItemId the CatalogItem ID
   * @returns the found CatalogItem, otherwise undefined
   */
  public getCatalogItem(catalogItemId: string): Observable<CatalogItem | undefined> {
    return this.catalogItemCollection.doc(catalogItemId).valueChanges().pipe(
      map((catalogItemJson) => {
        return catalogItemJson as CatalogItem; // plainToClass(CatalogItem, catalogItemJson);
      })
    );
  }

  /**
   * Removes CatalogItems.
   *
   * @param accountId the ID of the Account the CatalogItems belong to
   * @param catalogItemIds the IDs of the CatalogItems to remove
   */
  public async removeCatalogItems(accountId: string, catalogItemIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    const imageIdsToRemove: string[] = [];
    for (const catalogItemId of catalogItemIds) {
      const catalogItem: CatalogItem | undefined = await this.getCatalogItem(catalogItemId).pipe(first()).toPromise();
      if (catalogItem && catalogItem.imageIds) {
        imageIdsToRemove.push(...catalogItem.imageIds);
      }

      batch.delete(this.catalogItemCollection.doc(catalogItemId).ref);
    }
    batch.set(this.catalogCollection.doc(accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });
    await this.removeCatalogImages(imageIdsToRemove);

    await batch.commit();
  }

  /**
   * Changes visibility of CatalogItems.
   *
   * @param accountId the ID of the Account the CatalogMenus belong to
   * @param catalogItemIds the IDs of the CatalogItems to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfCatalogItems(accountId: string, catalogItemIds: string[], visible: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogItemId of catalogItemIds) {
      batch.update(this.catalogItemCollection.doc(catalogItemId).ref, { visibility: visible });
    }
    batch.set(this.catalogCollection.doc(accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

    await batch.commit();
  }

  /**
   * Saves CatalogItem order.
   *
   * @param catalogMenus the CatalogMenus
   * @param categoryItemMap the map of CatalogCategories to their CatalogItems
   */
  public async saveCatalogItemOrder(catalogMenus: CatalogMenu[], categoryItemMap: Map<string, CatalogItem[]>): Promise<void> {
    const batch = this.afs.firestore.batch();

    let ordinal = 1;
    for (const catalogMenu of catalogMenus) {
      if (catalogMenu.categories) {
        for (const catalogCategory of catalogMenu.categories) {
          const catalogItems: CatalogItem[] | undefined = categoryItemMap.get(catalogCategory.id);
          if (catalogItems) {
            for (const catalogItem of catalogItems) {
              if (catalogItem.ordinal !== ordinal) {
                batch.update(this.catalogItemCollection.doc(catalogItem.id).ref, {ordinal});
                batch.set(this.catalogCollection.doc(catalogItem.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });
              }
              ordinal++;
            }
          }
        }
      }
    }

    await batch.commit();
  }

  /**
   * Add new CatalogImage to cloud.
   *
   * @param catalogImage the CatalogImage object to add
   * @return the id of the new CatalogImage
   */
  public async addCatalogImage(catalogImage: CatalogImage): Promise<string> {
    if (!catalogImage.id) {
      catalogImage.id = this.afs.createId();
      catalogImage.createdAt = new Date().toISOString();
    }
    catalogImage.updatedAt = new Date().toISOString();

    await this.catalogImageCollection.doc(catalogImage.id).set(classToPlain(catalogImage) as CatalogImage, { merge: true });

    return catalogImage.id;
  }

  /**
   * Returns the CatalogImage specified by the ID.
   *
   * @param catalogImageId the CatalogImage ID
   * @returns the found CatalogImage, otherwise undefined
   */
  public getCatalogImage(catalogImageId: string): Observable<CatalogImage> {
    return this.catalogImageCollection.doc(catalogImageId).valueChanges().pipe(
      map((catalogImageJson) => {
        return catalogImageJson as CatalogImage; // plainToClass(CatalogImage, catalogImageJson);
      })
    );
  }

  /**
   * Removes CatalogImages.
   *
   * @param catalogImageIds the IDs of the CatalogImages to remove
   */
  public async removeCatalogImages(catalogImageIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogImageId of catalogImageIds) {
      batch.delete(this.catalogImageCollection.doc(catalogImageId).ref);
    }

    await batch.commit();
  }

  /**
   * Add new CatalogTax to cloud.
   *
   * @param catalogTax the CatalogTax object to add
   * @return the id of the new CatalogTax
   */
  public async addCatalogTax(catalogTax: CatalogTax): Promise<string> {
    if (!catalogTax.id) {
      catalogTax.id = this.afs.createId();
      catalogTax.createdAt = new Date().toISOString();
    }
    catalogTax.updatedAt = new Date().toISOString();

    await this.catalogTaxCollection.doc(catalogTax.id).set(classToPlain(catalogTax) as CatalogTax, { merge: true });

    return catalogTax.id;
  }

  /**
   * Returns all CatalogTaxes.
   *
   * @returns the found CatalogTaxes, otherwise empty list
   */
  public getAllCatalogTaxes(): Observable<CatalogTax[]> {
    return this.afs.collection<CatalogTax>('catalogTaxes', ref => ref.orderBy('abbreviation'))
      .valueChanges().pipe(
        map((catalogTaxesJson) => {
          return catalogTaxesJson as CatalogTax[]; // plainToClass(CatalogTax, catalogTaxesJson as object[]);
        })
      );
  }

  /**
   * Returns the CatalogTax specified by the ID.
   *
   * @param catalogTaxId the CatalogTax ID
   * @returns the found CatalogTax, otherwise undefined
   */
  public getCatalogTax(catalogTaxId: string): Observable<CatalogTax> {
    return this.catalogTaxCollection.doc(catalogTaxId).valueChanges().pipe(
      map((catalogTaxJson) => {
        return catalogTaxJson as CatalogTax; // plainToClass(CatalogTax, catalogTaxJson);
      })
    );
  }

  /**
   * Removes CatalogTaxes.
   *
   * @param catalogTaxIds the IDs of the CatalogTaxes to remove
   */
  public async removeCatalogTaxes(catalogTaxIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogTaxId of catalogTaxIds) {
      batch.delete(this.catalogTaxCollection.doc(catalogTaxId).ref);

      // remove references in CatalogItems
      await this.afs.collection<CatalogItem>('catalogItems', ref => ref.where('taxIds', 'array-contains', catalogTaxId))
        .valueChanges().pipe(
          first(),
          map((catalogItemsJson) => {
            return catalogItemsJson as CatalogItem[]; // plainToClass(CatalogItem, catalogItemsJson as object[]);
          }),
          switchMap((catalogItems: CatalogItem[]) => {
            catalogItems.forEach((item: CatalogItem) => {
              const index: number = item.taxIds.findIndex((id: string) => id === catalogTaxId);
              item.taxIds.splice(index, 1);
            });

            return this.addCatalogItems(catalogItems);
          })
        ).toPromise();
    }

    await batch.commit();
  }

  /**
   * Returns CatalogItems of a location whose titles match the search text.
   *
   * @param locationId the location id
   * @param searchText the search text
   * @returns the matching CatalogItems, otherwise empty list
   */
  public findCatalogItemsOfLocation(locationId: string, searchText?: string): Observable<CatalogItem[]> {
    return this.locationAccess.getLocation(locationId).pipe(
      switchMap((location: Location) => {
        return this.getAllCatalogItems(location.accountId);
      }),
      flatMap((items: CatalogItem[]) => {
        if (searchText !== undefined) {
          items = items.filter((item: CatalogItem) => item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        }

        return of(items);
      })
    );
  }

  /**
   * Returns CatalogItems nearby.
   *
   * @param latitude the position's latitude
   * @param longitude the position's longitude
   * @param limit the maximum number of items to return
   * @param startAfterId results start after the document with the provided ID (exclusive)
   * @returns the matching CatalogItems, otherwise empty list
   */
  public findCatalogItemsNearby(latitude: number, longitude: number, limit?: number, startAfterId?: string): Observable<CatalogItem[]> {
    // TODO temporarily return all CatalogItems
    if (startAfterId) {
      return this.catalogItemCollection.doc(startAfterId).get().pipe(
        switchMap((doc: DocumentSnapshot<CatalogItem>) => {
          return this.findCatalogItemsNearbyWithDoc(latitude, longitude, limit, doc.exists ? doc : undefined);
        })
      );
    } else {
      return this.findCatalogItemsNearbyWithDoc(latitude, longitude, limit);
    }
  }

  /**
   * Returns CatalogItems nearby.
   *
   * @param latitude the position's latitude
   * @param longitude the position's longitude
   * @param limit the maximum number of items to return
   * @param startAfterDoc results start after the provided document (exclusive)
   * @returns the matching CatalogItems, otherwise empty list
   */
  private findCatalogItemsNearbyWithDoc(latitude: number, longitude: number,
                                        limit?: number, startAfterDoc?: DocumentSnapshot<CatalogItem>): Observable<CatalogItem[]> {
    // TODO temporarily return all CatalogItems
    return this.afs.collection<CatalogItem>('catalogItems', ref => {
      let newRef: Query = ref.orderBy('updatedAt', 'desc');

      if (startAfterDoc && startAfterDoc.exists) {
        newRef = newRef.startAfter(startAfterDoc);
      }
      if (limit) {
        newRef = newRef.limit(limit);
      }

      return newRef;
    }).valueChanges().pipe(
      map((catalogItemsJson) => {
        return catalogItemsJson as CatalogItem[]; // plainToClass(CatalogItem, catalogItemsJson as object[]);
      })
    );
  }

  /**
   * Add new CatalogModifierList to cloud.
   *
   * @param catalogModifierList the CatalogModifierList object to add
   * @return the id of the new CatalogModifierList
   */
  public async addCatalogModifierList(catalogModifierList: CatalogModifierList): Promise<string> {
    const batch = this.afs.firestore.batch();
 
    if (!catalogModifierList.id) {
      catalogModifierList.id = this.afs.createId();
    }

    batch.set(this.catalogModifierListCollection.doc(catalogModifierList.id).ref, classToPlain(catalogModifierList) as CatalogModifierList, { merge: true });
    batch.set(this.catalogCollection.doc(catalogModifierList.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

    await batch.commit();

    return catalogModifierList.id;
  }

  /**
   * Returns all CatalogModifierLists of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogModifierLists, otherwise empty list
   */
  public getAllCatalogModifierLists(accountId: string): Observable<CatalogModifierList[]> {
    return this.afs.collection<CatalogModifierList>('catalogModifierLists', ref => ref.where('accountId', '==', accountId).orderBy('ordinal'))
      .valueChanges().pipe(
        map((catalogModifierListsJson) => {
          return catalogModifierListsJson as CatalogModifierList[]; // plainToClass(CatalogModifierList, catalogModifierListsJson as object[]);
        })
      );
  }

  /**
   * Returns the CatalogModifierList specified by the ID.
   *
   * @param catalogModifierListId the CatalogModifierList ID
   * @returns the found CatalogModifierList, otherwise undefined
   */
  public getCatalogModifierList(catalogModifierListId: string): Observable<CatalogModifierList> {
    return this.catalogModifierListCollection.doc(catalogModifierListId).valueChanges().pipe(
      map((catalogModifierListJson) => {
        return catalogModifierListJson as CatalogModifierList; // plainToClass(CatalogModifierList, catalogModifierListJson);
      })
    );
  }

  /**
   * Removes CatalogModifierLists.
   *
   * @param accountId the ID of the Account the CatalogModifiers belong to
   * @param catalogModifierListIds the IDs of the CatalogModifierLists to remove
   */
  public async removeCatalogModifierLists(accountId: string, catalogModifierListIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogModifierListId of catalogModifierListIds) {
      batch.delete(this.catalogModifierListCollection.doc(catalogModifierListId).ref);
    }
    batch.set(this.catalogCollection.doc(accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

    await batch.commit();
  }

  /**
   * Changes visibility of CatalogModifierLists.
   *
   * @param accountId the ID of the Account the CatalogModifierLists belong to
   * @param catalogModifierListIds the IDs of the CatalogModifierLists to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfCatalogModifierLists(accountId: string, catalogModifierListIds: string[], visible: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogModifierListId of catalogModifierListIds) {
      batch.update(this.catalogModifierListCollection.doc(catalogModifierListId).ref, { visibility: visible });
    }
    batch.set(this.catalogCollection.doc(accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

    await batch.commit();
  }

  /**
   * Saves CatalogModifierList order.
   *
   * @param catalogModifierLists the CatalogModifierLists in the order to save
   */
  public async saveCatalogModifierListOrder(catalogModifierLists: CatalogModifierList[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    let ordinal = 1;
    for (const catalogModifierList of catalogModifierLists) {
      batch.update(this.catalogModifierListCollection.doc(catalogModifierList.id).ref, { ordinal });
      batch.set(this.catalogCollection.doc(catalogModifierList.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });
      ordinal++;
    }

    await batch.commit();
  }

  /**
   * Adds new CatalogModifiers to cloud.
   *
   * @param catalogModifiers the CatalogModifier objects to add
   * @return the IDs of the new CatalogModifiers
   */
  public async addCatalogModifiers(catalogModifiers: CatalogModifier[]): Promise<string[]> {
    const batch = this.afs.firestore.batch();
    const ids: string[] = [];

    for (const catalogModifier of catalogModifiers) {
      if (!catalogModifier.id) {
        catalogModifier.id = this.afs.createId();
        catalogModifier.createdAt = new Date().toISOString();
      }
      catalogModifier.updatedAt = new Date().toISOString();

      batch.set(this.catalogModifierCollection.doc(catalogModifier.id).ref, classToPlain(catalogModifier) as CatalogModifier, { merge: true });
      batch.set(this.catalogCollection.doc(catalogModifier.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

      ids.push(catalogModifier.id);
    }

    await batch.commit();

    return ids;
  }

  /**
   * Returns all CatalogModifiers of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogModifiers, otherwise empty list
   */
  public getAllCatalogModifiers(accountId: string): Observable<CatalogModifier[]> {
    return this.afs.collection<CatalogModifier>('catalogModifiers', ref => ref.where('accountId', '==', accountId).orderBy('ordinal'))
      .valueChanges().pipe(
        map((catalogModifiersJson) => {
          return catalogModifiersJson as CatalogModifier[]; // plainToClass(CatalogModifier, catalogModifiersJson as object[]);
        })
      );
  }

  /**
   * Returns all CatalogModifiers of a CatalogModifierList.
   *
   * @param catalogModifierListId the ID of the CatalogModifierList
   * @returns the found CatalogModifiers, otherwise empty list
   */
  public getCatalogModifiersOfList(catalogModifierListId: string): Observable<CatalogModifier[]> {
    return this.afs.collection<CatalogModifier>('catalogModifiers', ref => ref.where('modifierListId', '==', catalogModifierListId).orderBy('ordinal'))
      .valueChanges().pipe(
        map((catalogModifiersJson) => {
          return catalogModifiersJson as CatalogModifier[]; // plainToClass(CatalogModifier, catalogModifiersJson as object[]);
        })
      );
  }

  /**
   * Returns the CatalogModifier specified by the ID.
   *
   * @param catalogModifierId the CatalogModifier ID
   * @returns the found CatalogModifier, otherwise undefined
   */
  public getCatalogModifier(catalogModifierId: string): Observable<CatalogModifier> {
    return this.catalogModifierCollection.doc(catalogModifierId).valueChanges().pipe(
      map((catalogModifierJson) => {
        return catalogModifierJson as CatalogModifier; // plainToClass(CatalogModifier, catalogModifierJson);
      })
    );
  }

  /**
   * Removes CatalogModifiers.
   *
   * @param accountId the ID of the Account the CatalogModifiers belong to
   * @param catalogModifierIds the IDs of the CatalogModifiers to remove
   */
  public async removeCatalogModifiers(accountId: string, catalogModifierIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogModifierId of catalogModifierIds) {
      batch.delete(this.catalogModifierCollection.doc(catalogModifierId).ref);
    }
    batch.set(this.catalogCollection.doc(accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

    await batch.commit();
  }

  /**
   * Changes visibility of CatalogModifiers.
   *
   * @param accountId the ID of the Account the CatalogModifiers belong to
   * @param catalogModifierIds the IDs of the CatalogModifiers to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfCatalogModifiers(accountId: string, catalogModifierIds: string[], visible: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogModifierId of catalogModifierIds) {
      batch.update(this.catalogModifierCollection.doc(catalogModifierId).ref, { visibility: visible });
    }
    batch.set(this.catalogCollection.doc(accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

    await batch.commit();
  }

  /**
   * Saves CatalogModifier order.
   *
   * @param catalogModifiers the CatalogModifiers in the order to save
   */
  public async saveCatalogModifierOrder(catalogModifiers: CatalogModifier[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    let ordinal = 1;
    for (const catalogModifier of catalogModifiers) {
      batch.update(this.catalogModifierCollection.doc(catalogModifier.id).ref, { ordinal });
    batch.set(this.catalogCollection.doc(catalogModifier.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });
      ordinal++;
    }

    await batch.commit();
  }

  /**
   * Adds new CatalogDiscounts to cloud.
   *
   * @param catalogDiscounts the CatalogDiscount objects to add
   * @return the IDs of the new CatalogDiscounts
   */
  public async addCatalogDiscounts(catalogDiscounts: CatalogDiscount[]): Promise<string[]> {
    const ids: string[] = [];

    for (const catalogDiscount of catalogDiscounts) {
      if (!catalogDiscount.id) {
        catalogDiscount.id = this.afs.createId();
        catalogDiscount.createdAt = new Date().toISOString();
      }
      catalogDiscount.updatedAt = new Date().toISOString();

      await this.catalogDiscountCollection.doc(catalogDiscount.id).set(classToPlain(catalogDiscount) as CatalogDiscount, { merge: true });

      ids.push(catalogDiscount.id);
    }

    return ids;
  }

  /**
   * Returns all CatalogDiscounts of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogDiscounts, otherwise empty list
   */
  public getAllCatalogDiscounts(accountId: string): Observable<CatalogDiscount[]> {
    return this.afs.collection<CatalogDiscount>('catalogDiscounts', ref => ref.where('accountId', '==', accountId).orderBy('createdAt'))
      .valueChanges().pipe(
        map((catalogDiscountsJson) => {
          return catalogDiscountsJson as CatalogDiscount[]; // plainToClass(CatalogDiscount, catalogDiscountsJson as object[]);
        })
      );
  }

  /**
   * Returns the CatalogDiscount specified by the ID.
   *
   * @param catalogDiscountId the CatalogDiscount ID
   * @returns the found CatalogDiscount, otherwise undefined
   */
  public getCatalogDiscount(catalogDiscountId: string): Observable<CatalogDiscount> {
    return this.catalogDiscountCollection.doc(catalogDiscountId).valueChanges().pipe(
      map((catalogDiscountJson) => {
        return catalogDiscountJson as CatalogDiscount; // plainToClass(CatalogDiscount, catalogDiscountJson);
      })
    );
  }

  /**
   * Removes CatalogDiscounts.
   *
   * @param catalogDiscountIds the IDs of the CatalogDiscounts to remove
   */
  public async removeCatalogDiscounts(catalogDiscountIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogDiscountId of catalogDiscountIds) {
      batch.delete(this.catalogDiscountCollection.doc(catalogDiscountId).ref);
    }

    await batch.commit();
  }

  /**
   * Add new CatalogPricingRule objects to cloud.
   *
   * @param catalogPricingRules the CatalogPricingRule objects to add
   * @return the IDs of the new CatalogPricingRules
   */
  public async addCatalogPricingRules(catalogPricingRules: CatalogPricingRule[]): Promise<string[]> {
    const ids: string[] = [];

    for (const catalogPricingRule of catalogPricingRules) {
      if (!catalogPricingRule.id) {
        catalogPricingRule.id = this.afs.createId();
        catalogPricingRule.createdAt = new Date().toISOString();
      }
      catalogPricingRule.updatedAt = new Date().toISOString();

      await this.catalogPricingRuleCollection.doc(catalogPricingRule.id).set(classToPlain(catalogPricingRule) as CatalogDiscount, { merge: true });

      ids.push(catalogPricingRule.id);
    }

    return ids;
  }

  /**
   * Returns all CatalogPricingRules of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogPricingRules, otherwise empty list
   */
  public getAllCatalogPricingRules(accountId: string): Observable<CatalogPricingRule[]> {
    return this.afs.collection<CatalogPricingRule>('catalogPricingRules', ref => ref.where('accountId', '==', accountId).orderBy('createdAt'))
      .valueChanges().pipe(
        map((catalogPricingRulesJson) => {
          return catalogPricingRulesJson as CatalogPricingRule[]; // plainToClass(CatalogPricingRule, catalogPricingRulesJson as object[]);
        })
      );
  }

  /**
   * Returns the CatalogPricingRules belonging to the CatalogDiscount.
   *
   * @param catalogDiscountId the ID of the CatalogDiscount the CatalogPricingRules belong to
   * @returns the found CatalogPricingRules, otherwise empty
   */
  public getCatalogPricingRulesOfDiscount(catalogDiscountId: string): Observable<CatalogPricingRule[]> {
    return this.afs.collection<CatalogPricingRule>('catalogPricingRules', ref => ref.where('discountId', '==', catalogDiscountId))
      .valueChanges().pipe(
        map((catalogPricingRulesJson) => {
          return catalogPricingRulesJson as CatalogPricingRule[]; // plainToClass(CatalogPricingRule, catalogPricingRulesJson as object[]);
        })
      );
  }

  /**
   * Removes CatalogPricingRules.
   *
   * @param catalogPricingRuleIds the IDs of the CatalogPricingRules to remove
   */
  public async removeCatalogPricingRules(catalogPricingRuleIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogPricingRuleId of catalogPricingRuleIds) {
      batch.delete(this.catalogPricingRuleCollection.doc(catalogPricingRuleId).ref);
    }

    await batch.commit();
  }

  /**
   * Adds new CatalogDiscountCode objects to cloud.
   *
   * @param catalogDiscountCodes the CatalogDiscountCode objects to add
   * @return the IDs of the new CatalogDiscountCodes
   */
  public async addCatalogDiscountCodes(catalogDiscountCodes: CatalogDiscountCode[]): Promise<string[]> {
    const ids: string[] = [];

    for (const catalogDiscountCode of catalogDiscountCodes) {
      if (!catalogDiscountCode.id) {
        catalogDiscountCode.id = this.afs.createId();
        catalogDiscountCode.createdAt = new Date().toISOString();
      }
      catalogDiscountCode.updatedAt = new Date().toISOString();

      await this.catalogDiscountCodeCollection.doc(catalogDiscountCode.id).set(classToPlain(catalogDiscountCode) as CatalogDiscountCode, { merge: true });

      ids.push(catalogDiscountCode.id);
    }

    return ids;
  }

  /**
   * Returns a CatalogDiscountCode by using its code.
   *
   * @param accountId the ID of the account
   * @param code the code of the CatalogDiscountCode
   * @returns the found CatalogDiscountCode, otherwise undefined
   */
  public getCatalogDiscountCodeByCode(accountId: string, code: string): Observable<CatalogDiscountCode | undefined> {
    return this.afs.collection<CatalogDiscountCode>('catalogDiscountCodes', ref => ref.where('accountId', '==', accountId).where('code', '==', code))
      .valueChanges().pipe(
        map((catalogDiscountCodesJson) => {
          return catalogDiscountCodesJson as CatalogDiscountCode[]; // plainToClass(CatalogDiscountCode, catalogDiscountCodesJson as object[]);
        }),
        map((discountCodes: CatalogDiscountCode[]) => discountCodes.length > 0 ? discountCodes[0] : undefined)
      );
  }

  /**
   * Returns all CatalogDiscountCodes of an account.
   *
   * @param accountId the ID of the account
   * @returns the found CatalogDiscountCodes, otherwise empty list
   */
  public getAllCatalogDiscountCodes(accountId: string): Observable<CatalogDiscountCode[]> {
    return this.afs.collection<CatalogDiscountCode>('catalogDiscountCodes', ref => ref.where('accountId', '==', accountId).orderBy('createdAt'))
      .valueChanges().pipe(
        map((catalogDiscountCodesJson) => {
          return catalogDiscountCodesJson as CatalogDiscountCode[]; // plainToClass(CatalogDiscountCode, catalogDiscountCodesJson as object[]);
        })
      );
  }

  /**
   * Removes CatalogDiscountCodes.
   *
   * @param catalogDiscountCodeIds the IDs of the CatalogDiscountCodes
   */
  public async removeCatalogDiscountCodes(catalogDiscountCodeIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogDiscountCodeId of catalogDiscountCodeIds) {
      batch.delete(this.catalogDiscountCodeCollection.doc(catalogDiscountCodeId).ref);
    }

    await batch.commit();
  }

  /**
   * Add new CatalogLabel to cloud.
   *
   * @param catalogLabel the CatalogLabel object to add
   * @return the id of the new CatalogLabel
   */
  public async addCatalogLabel(catalogLabel: CatalogLabel): Promise<string> {
    const batch = this.afs.firestore.batch();

    if (!catalogLabel.id) {
      catalogLabel.id = this.afs.createId();
    }

    batch.set(this.catalogLabelCollection.doc(catalogLabel.id).ref, classToPlain(catalogLabel) as CatalogLabel, { merge: true });
    batch.set(this.catalogCollection.doc(catalogLabel.accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

    await batch.commit();

    return catalogLabel.id;
  }

  /**
   * Returns all CatalogLabels.
   *
   * @param accountId the ID of the account
   * @param type the label type
   * @returns the found CatalogLabels, otherwise empty list
   */
  public getAllCatalogLabels(accountId: string, type?: string): Observable<CatalogLabel[]> {
    return this.afs.collection<CatalogLabel>('catalogLabels', ref => {
      let newRef = ref.where('accountId', '==', accountId);

      if (type) {
        newRef = newRef.where('type', '==', type);
      }

      return newRef;
    }).valueChanges().pipe(
        map((catalogLabelsJson) => {
          return catalogLabelsJson as CatalogLabel[]; // plainToClass(CatalogLabel, catalogLabelsJson as object[]);
        })
      );
  }

  /**
   * Returns the CatalogLabel specified by the ID.
   *
   * @param catalogLabelId the CatalogLabel ID
   * @returns the found CatalogLabel, otherwise undefined
   */
  public getCatalogLabel(catalogLabelId: string): Observable<CatalogLabel> {
    return this.catalogLabelCollection.doc(catalogLabelId).valueChanges().pipe(
      map((catalogLabelJson) => {
        return catalogLabelJson as CatalogLabel; // plainToClass(CatalogLabel, catalogLabelJson);
      })
    );
  }

  /**
   * Removes CatalogLabels.
   *
   * @param accountId the ID of the Account the CatalogLabels belong to
   * @param catalogLabelIds the IDs of the CatalogLabels to remove
   */
  public async removeCatalogLabels(accountId: string, catalogLabelIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const catalogLabelId of catalogLabelIds) {
      batch.delete(this.catalogLabelCollection.doc(catalogLabelId).ref);
    }
    batch.set(this.catalogCollection.doc(accountId).ref, { updatedAt: firebase.firestore.Timestamp.now().toDate().toISOString() });

    await batch.commit();
  }
}
