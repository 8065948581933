import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { Location } from '../models/location';
import { LocationImage } from '../models/location-image';
import { LocationPlace } from '../models/location-place';
import { OrderService } from '../models/order-service';
import { LocationAccess } from '../access/location-access.service';
import { OrderServiceAccess } from '../access/order-service-access.service';
import { GeoPositionAccess } from '../access/geo-position-access.service';
import { FoursquareLocationAccess } from '../access/foursquare-location-access.service';


/**
 * Class providing management methods for locations.
 */
@Injectable({
  providedIn: 'root'
})
export class LocationManager {

  /**
   * The default constructor.
   */
  constructor(
    private geoPositionAccess: GeoPositionAccess,
    private locationAccess: LocationAccess,
    private orderServiceAccess: OrderServiceAccess,
    private foursquareLocationAccess: FoursquareLocationAccess
  ) {
  }

  /**
   * Add new Location to cloud.
   *
   * @param location the Location object to add
   * @return the id of the new Location
   */
  public async addLocation(location: Location): Promise<string> {
    return this.locationAccess.addLocation(location);
  }

  /**
   * Returns all locations.
   *
   * @returns the found locations, otherwise empty list
   */
  public getAllLocations(): Observable<Location[]> {
    return this.locationAccess.getAllLocations();
  }

  /**
   * Returns all locations of an account.
   *
   * @param accountId the ID of the account
   * @returns the found locations, otherwise empty list
   */
  public getAllLocationsOfAccount(accountId: string): Observable<Location[]> {
    return this.locationAccess.getAllLocationsOfAccount(accountId);
  }

  /**
   * Returns the Locations specified by the IDs.
   *
   * @param locationIds the Location IDs
   * @returns the found locations, otherwise empty list
   */
  public getLocations(locationIds: string[]): Observable<Location[]> {
    return this.locationAccess.getLocations(locationIds);
  }

  /**
   * Returns the Location specified by the ID.
   *
   * @param locationId the Location ID
   * @returns the found location, otherwise undefined
   */
  public getLocation(locationId: string): Observable<Location | undefined> {
    return this.locationAccess.getLocation(locationId);
  }

  /**
   * Returns the Location specified by the link name.
   *
   * @param locationLinkName the Location link name
   * @returns the found location, otherwise undefined
   */
  public getLocationByLinkName(locationLinkName: string): Observable<Location | undefined> {
    return this.locationAccess.getLocationByLinkName(locationLinkName);
  }

  /**
   * Removes Locations.
   *
   * @param locationIds the IDs of the Locations to remove
   */
  public async removeLocations(locationIds: string[]): Promise<void> {
    for (const locationId of locationIds) {
      const services: OrderService[] = await this.orderServiceAccess.getAllOrderServices(locationId).pipe(first()).toPromise();
      if (services.length > 0) {
        await this.orderServiceAccess.removeOrderServices(services.map((service: OrderService) => service.id));
      }
    }

    return this.locationAccess.removeLocations(locationIds);
  }

  /**
   * Changes visibility of Locations.
   *
   * @param locationIds the IDs of the Locations to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfLocations(locationIds: string[], visible: string): Promise<void> {
    return this.locationAccess.setVisibilityOfLocations(locationIds, visible);
  }

  /**
   * Add new LocationImage to cloud.
   *
   * @param locationImage the LocationImage object to add
   * @return the id of the new LocationImage
   */
  public async addLocationImage(locationImage: LocationImage): Promise<string> {
    return this.locationAccess.addLocationImage(locationImage);
  }

  /**
   * Returns the LocationImage specified by the ID.
   *
   * @param locationImageId the LocationImage ID
   * @returns the found locationImage, otherwise undefined
   */
  public getLocationImage(locationImageId: string): Observable<LocationImage | undefined> {
    return this.locationAccess.getLocationImage(locationImageId);
  }

  /**
   * Removes LocationImages.
   *
   * @param locationImageIds the IDs of the LocationImages to remove
   */
  public async removeLocationImages(locationImageIds: string[]): Promise<void> {
    return this.locationAccess.removeLocationImages(locationImageIds);
  }

  /**
   * Returns locations nearby whose titles match the search text.
   *
   * @param searchText the search text, optional
   * @param radius the search radius, default 300m, null for unlimited
   * @param limit the maximum number of results, default 30
   * @returns the matching locations, otherwise empty list
   */
  public async findLocationsNearby(searchText?: string, radius: number | null = 300, limit: number = 30): Promise<Location[]> {
    const position: number[] = await this.geoPositionAccess.getCurrentPosition();

    return await this.foursquareLocationAccess.findLocationsNearby(position[0], position[1], searchText, radius, limit);
  }

  /**
   * Returns nearby locations.
   *
   * @param latitude the position's latitude of the user
   * @param longitude the position's longitude of the user
   * @param searchText the search text, optional
   * @param radius the search radius, default 300m, null for unlimited
   * @param limit the maximum number of results, default 30
   * @returns the matching locations, otherwise empty list
   */
  public async findLocations(latitude: number, longitude: number, searchText?: string,
                             radius: number | null = 300, limit: number = 30): Promise<Location[]> {
    return await this.foursquareLocationAccess.findLocationsNearby(latitude, longitude, searchText, radius, limit);
  }

  /**
   * Add new LocationPlace to cloud.
   *
   * @param locationPlace the LocationPlace object to add
   * @return the id of the new LocationPlace
   */
  public async addLocationPlace(locationPlace: LocationPlace): Promise<string> {
    return this.locationAccess.addLocationPlace(locationPlace);
  }

  /**
   * Returns all LocationPlaces of an account.
   *
   * @param accountId the ID of the account
   * @returns the found LocationPlaces, otherwise empty list
   */
  public getAllLocationPlacesOfAccount(accountId: string): Observable<LocationPlace[]> {
    return this.locationAccess.getAllLocationPlacesOfAccount(accountId);
  }

  /**
   * Returns all LocationPlaces of a location.
   *
   * @param locationId the ID of the location
   * @returns the found LocationPlaces, otherwise empty list
   */
  public getAllLocationPlaces(locationId: string): Observable<LocationPlace[]> {
    return this.locationAccess.getAllLocationPlaces(locationId);
  }

  /**
   * Returns the LocationPlace specified by the ID.
   *
   * @param locationPlaceId the LocationPlace ID
   * @returns the found LocationPlace, otherwise undefined
   */
  public getLocationPlace(locationPlaceId: string): Observable<LocationPlace | undefined> {
    return this.locationAccess.getLocationPlace(locationPlaceId);
  }

  /**
   * Removes LocationPlaces.
   *
   * @param locationPlaceIds the IDs of the LocationPlaces to remove
   */
  public async removeLocationPlaces(locationPlaceIds: string[]): Promise<void> {
    return this.locationAccess.removeLocationPlaces(locationPlaceIds);
  }
}
