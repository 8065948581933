
/**
 * Class representing a user.
 */
export class User {

  /** The unique ID of this object. */
  public id: string;
  /** The unique IDs of the accounts the object belongs to. */
  public accountIds: string[] = [];

  public salutation?: string | null;
  public firstname?: string | null;
  public lastname?: string | null;
  public nickName: string | null;
  public email: string | null;
  public photoUrl?: string | null;
  public eventIds?: string[] = [];
  public paypalMe?: string;

  /** The user's phone number. */
  public phoneNumber?: string;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;

  public isAdmin: boolean;

}
