import { Money } from './money';
import { Address } from './address';


/**
 * Represents a company account extension.
 */
export class AccountExtension {

  /** The unique ID of this object. */
  public id: string;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;

  /** The company name for invoices. */
  public invoiceCompanyName?: string;

  /**
   * The mode for creating invoices.
   *
   * PRE_PAYOUT: Invoice with payouts already made
   * POST_PAYOUT: Invoice with subsequent payout
   */
  public invoiceMode = 'POST_PAYOUT';

  /** The name (first and last name) for invoices. */
  public invoiceName?: string;

  /** The address for invoices. */
  public invoiceAddress?: Address;

  /** The company tax ID for invoices. */
  public invoiceTaxId?: string;

  /** The company tax number for invoices. */
  public invoiceTaxNumber?: string;

  /** The email address for invoices. */
  public invoiceEmail?: string;

  /**
   * References to external services like payment service providers for this account sending payments.
   */
  public paymentSender: { name: string, ref: string }[] = [];

  /**
   * References to external services like payment service providers for this account receiving payments.
   */
  public paymentRecipient: { name: string, ref: string }[] = [];

  /** Indicates whether to create direct charges on a connected account. */
  public paymentDirectCharges = false;

  /** The percent-based commission per dine-in order, e.g. 5 for 5%. */
  public paymentDineInCommissionPercent = 0.9;

  /** The fixed fee per dine-in order. */
  public paymentDineInCommissionAmountMoney: Money = new Money();

  /** The percent-based commission per pick-up order, e.g. 5 for 5%. */
  public paymentPickupCommissionPercent = 5.5;

  /** The fixed fee per pick-up order. */
  public paymentPickupCommissionAmountMoney: Money = new Money();

  /** The percent-based commission per shipment order, e.g. 5 for 5%. */
  public paymentShipmentCommissionPercent = 5.5;

  /** The fixed fee per shipment order. */
  public paymentShipmentCommissionAmountMoney: Money = new Money();

  /** The percent-based fee per transaction, e.g. 3 for 3%. */
  public paymentTransactionFeePercent = 3;

  /** The fixed fee per transaction. */
  public paymentTransactionFeeAmountMoney: Money = new Money(0.25);

  /** Indicates whether ordering is enabled. */
  public ordering = false;

  /** The version of the terms and conditions that the account was agreed to. */
  public termsVersion?: string;
}
