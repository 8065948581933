
/**
 * Utility class for array operations.
 */
export class ArrayUtils {

  /**
   * Merges multiple arrays.
   *
   * @param arrays all arrays to merge
   * @returns the array with merged elements
   */
  static mergeArrays<T>(...arrays: T[][]): T[] {
    let mergedArray: T[] = [];

    if (arrays) {
      mergedArray = mergedArray.concat(...(arrays.filter((array: T[]) => array !== undefined && array !== null && array.length > 0)));
      mergedArray = this.removeDuplicates(mergedArray);
    }

    return mergedArray;
  }

  static splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
    const result: T[][] = [];
    const arrayLength = array.length;
    let index = 0;

    while (index < arrayLength) {
      result.push(array.slice(index, index + chunkSize));
      index += chunkSize;
    }

    return result;
  }

  /**
   * Returns the array without duplicates.
   *
   * @param array the array to clean
   * @returns the cleaned array
   */
  static removeDuplicates<T>(array: T[]): T[] {
    return array.filter((element: T, index: number) => array.indexOf(element) === index);
  }
}
