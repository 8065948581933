
/**
 * Represents an amount of money.
 */
export class Money {
  /**
   * The amount of money, in the smallest denomination of the currency indicated by currency.
   * For example, when currency is USD, amount is in cents.
   */
  public amount: number;
  /**
   * The type of currency, in ISO 4217 format.
   * For example, the currency code for US dollars is USD. See Currency for possible values.
   */
  public currency: string;

  /**
   * Default constructor.
   */
  constructor(amount: number = 0, currency: string = 'EUR') {
    this.amount = amount;
    this.currency = currency;
  }
}
