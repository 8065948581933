
/**
 * Represents a dictionary with translations for a language..
 */
export class LanguageDictionary {
  /** The language code. */
  public lang = '';

  /** The type of the language asset. OWNER for user generated translations, GOOGLE for the Google Translation API etc. */
  public sourceType = '';

  /** The map of translations. */
  public translations: { [key: string]: string | null } = {};
}
