import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { StorageFile } from '../models/storage-file';


/**
 * Class providing access methods for the Cloudinary storage.
 */
@Injectable({
  providedIn: 'root'
})
export class CloudinaryStorageAccess {

  public static readonly NAME = environment.cloudinaryConfig.imageRefName;

  /**
   * The default constructor.
   */
  constructor(
  ) {
  }

  /**
   * Uploads a file to the cloud storage.
   *
   * @param accountId the account ID
   * @param file the file to upload
   * @param folder the folder the file to upload to, optional
   * @returns the uploaded StorageImage
   */
  public uploadFile(accountId: string, file: File, folder?: string): Promise<StorageFile> {
    return this.uploadFileToCloudinary(accountId, file, folder);
  }

  /**
   * Uploads a file to the cloud storage.
   *
   * @param accountId the account ID
   * @param dataUrl the file as a based64 encoded data URL to upload
   * @param folder the folder the file to upload to, optional
   * @returns the uploaded StorageImage
   */
  public uploadFileByDataUrl(accountId: string, dataUrl: string, folder?: string): Promise<StorageFile> {
    return this.uploadFileToCloudinary(accountId, dataUrl, folder);
  }

  /**
   * Uploads a file to the cloud storage.
   * See https://cloudinary.com/documentation/image_upload_api_reference
   *
   * @param accountId the account ID
   * @param file the file to upload. It can be
   *             - a local file path
   *             - the Data URI (Base64 encoded), max ~60MB (62,910,000 chars)
   * @param folder the folder the file to upload to, optional
   * @returns the URL to the uploaded file
   */
  private uploadFileToCloudinary(accountId: string, file: File | string, folder?: string): Promise<StorageFile> {
    return new Promise<StorageFile>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const fd = new FormData();
      fd.append('upload_preset', environment.cloudinaryConfig.unsignedUploadPreset);
      fd.append('file', file);
      if (folder) {
        fd.append('folder', folder);
      }
      fd.append('tags', accountId);

      xhr.open('POST', `https://api.cloudinary.com/v1_1/${environment.cloudinaryConfig.cloudName}/image/upload`, true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            const image: StorageFile = new StorageFile();

            // File uploaded successfully
            const response = JSON.parse(xhr.responseText);
            image.refs = [{ name: CloudinaryStorageAccess.NAME, ref: response.public_id }];
            image.url = response.secure_url;
            image.accountId = accountId;

            resolve(image);
          } else {
            reject('Failed to upload file.');
          }
        }
      };

      xhr.send(fd);
    });
  }

  /**
   * Removes files.
   *
   * @param storageFiles the files to remove
   */
  public async removeFiles(storageFiles: StorageFile[]): Promise<void> {
    // TODO
    // Please note that calling the Admin API's resource method is not supported via client side.
    // Moreover, using admin API requires the account's secret key.
    // It should not be used in client code since it leaks that secret.

    /*
    const publicIds: string[] = [];

    for (const storageFile of storageFiles) {
      if (storageFile.refs && storageFile.refs.length > 0 && storageFile.refs[0].name === CloudinaryStorageAccess.NAME) {
        publicIds.push(storageFile.refs[0].ref);
      }
    }

    return new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const fd = new FormData();
      for (const publicId of publicIds) {
        fd.append('public_ids[]', publicId);
      }

      xhr.open('DELETE', `https://api.cloudinary.com/v1_1/${environment.cloudinaryConfig.cloudName}/resources/image/upload`, true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject('Failed to upload file.');
          }
        }
      };

      xhr.send(fd);
    });
    */
  }
}
