import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-select-locations',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss'],
})
export class SelectDialogComponent implements OnInit {

  @Input() title?: string;
  @Input() selectAllText?: string;
  @Input() options: any[];
  @Input() selections: boolean[];
  @Input() propertyName = 'name';

  constructor(
    protected modalController: ModalController,
    protected translateService: TranslateService
  ) {
  }

  ngOnInit() {
    if (!this.selectAllText) {
      this.selectAllText = this.translateService.instant('SELECT_DIALOG.SELECT_ALL');
    }

    if (!this.selections || this.selections.length === 0) {
      this.selections = new Array(this.options.length);
      this.selections.fill(true);
    }
  }

  public selectAll(): void {
    if (!this.selections.includes(false)) {
      this.selections.fill(false);
    } else {
      this.selections.fill(true);
    }
  }

  public async closeModal(cancel: boolean): Promise<void> {
    if (cancel) {
      await this.modalController.dismiss();
    } else {
      await this.modalController.dismiss(this.selections);
    }
  }
}
