
/**
 * A file.
 */
export class StorageFile {
  /** The internal name of this file. Identifies this file in calls to the cloud. */
  public id: string;
  /** The account's unique ID the object belongs to. */
  public accountId: string;
  /** The URL of this file. Generated by the external storage after a file is uploaded to the endpoint. */
  public url: string;

  /**
   * The order in which this object should be displayed.
   * On writes, the ordinal for each object within a parent is set according to the
   * object's position. On reads, the value is not guaranteed to be sequential or unique.
   */
  public ordinal = 0;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;

  /**
   * References to external hosting services like Firebase or Cloudinary.
   */
  public refs: { name: string, ref: string }[];
}
