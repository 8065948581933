import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';

import { Customer } from '../models/customer';
import { environment } from '../../../environments/environment';


/**
 * Class providing access methods for customers.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomerAccess {

  private customerCollection: AngularFirestoreCollection<Customer>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore,
    private http: HttpClient
  ) {
    this.customerCollection = afs.collection<Customer>('customers');
  }

  /**
   * Add new Customer to cloud.
   *
   * @param customer the Customer object to add
   * @return the id of the new Customer
   */
  public async addCustomer(customer: Customer): Promise<string> {
    if (!customer.id) {
      customer.id = this.afs.createId();
      customer.createdAt = new Date().toISOString();
    }
    customer.updatedAt = new Date().toISOString();

    await this.customerCollection.doc(customer.id).set(classToPlain(customer) as Customer, { merge: true });

    return customer.id;
  }

  /**
   * Update a Customer.
   *
   * @param customer the Customer object to update
   * @return the id of the Customer
   */
  public async updateCustomer(customer: Customer): Promise<string> {
    customer.updatedAt = new Date().toISOString();

    await this.customerCollection.doc(customer.id).set(classToPlain(customer) as Customer, { merge: true });

    return customer.id;
  }

  /**
   * Returns all customers.
   *
   * @param fromTime the from date in RFC 3339 format
   * @param toTime the to date in RFC 3339 format
   * @returns the found customers, otherwise empty list
   */
  public getAllCustomers(fromTime?: string, toTime?: string): Observable<Customer[]> {
    return this.afs.collection<Customer>('customers', ref => {
      let newRef = ref.orderBy('createdAt');

      if (fromTime !== undefined) {
        newRef = newRef.where('createdAt', '>=', fromTime);
      }
      if (toTime !== undefined) {
        newRef = newRef.where('createdAt', '<', toTime);
      }

      return newRef;
    }).valueChanges().pipe(
      map((customersJson) => {
        return customersJson as Customer[]; // plainToClass(Customer, customersJson as object[]);
      })
    );
  }

  /**
   * Returns the Customer specified by the ID.
   *
   * @param customerId the Customer ID
   * @returns the found Customer, otherwise undefined
   */
  public getCustomer(customerId: string): Observable<Customer | undefined> {
    return this.customerCollection.doc(customerId).valueChanges().pipe(
      map((customerJson) => {
        return customerJson as Customer; // plainToClass(Customer, customerJson);
      })
    );
  }

  /**
   * Returns the Customer specified by the firebase user ID.
   *
   * @param firebaseUserId the ID of the firebase user associated with this customer
   * @returns the found Customer, otherwise undefined
   */
  public getCustomerByFirebaseUser(firebaseUserId: string): Observable<Customer | undefined> {
    return this.afs.collection<Customer>(
      'customers',
      ref => ref.where('firebaseUserId', '==', firebaseUserId)
    ).valueChanges().pipe(
      map((customersJson) => {
        return customersJson as Customer[]; // plainToClass(Customer, customersJson as object[]);
      }),
      map((customers: Customer[]) => customers.length > 0 ? customers[0] : undefined)
    );
  }

  /**
   * Returns the Customer specified by the email address.
   *
   * @param email the customer's email address
   * @returns the found Customer, otherwise undefined
   */
  public getCustomerByEmail(email: string): Observable<Customer | undefined> {
    return this.afs.collection<Customer>(
      'customers',
      ref => ref.where('email', '==', email)
    ).valueChanges().pipe(
      map((customersJson) => {
        return customersJson as Customer[]; // plainToClass(Customer, customersJson as object[]);
      }),
      map((customers: Customer[]) => customers.length > 0 ? customers[0] : undefined)
    );
  }

  /**
   * Returns the Customer specified by the reference ID.
   *
   * @param referenceId the reference ID
   * @param referenceService the reference service
   * @returns the found Customer, otherwise undefined
   */
  public getCustomerByReference(referenceId: string, referenceService: string): Observable<Customer | undefined> {
    return this.afs.collection<Customer>(
      'customers',
      ref => ref.where('refs', 'array-contains', referenceService + ':' + referenceId)
    ).valueChanges().pipe(
      map((customersJson) => {
        return customersJson as Customer[]; // plainToClass(Customer, customersJson as object[]);
      }),
      map((customers: Customer[]) => customers.length > 0 ? customers[0] : undefined)
    );
  }

  /**
   * Returns the unverified Customer specified by the verification code.
   *
   * @param verificationCode the verification code
   * @returns the found Customer, otherwise undefined
   */
  public getCustomerByVerificationCode(verificationCode: string): Observable<Customer | undefined> {
    return this.afs.collection<Customer>(
      'customers',
      ref => ref.where('verificationCode', '==', verificationCode).where('verified', '==', false)
    ).valueChanges().pipe(
      map((customersJson) => {
        return customersJson as Customer[]; // plainToClass(Customer, customersJson as object[]);
      }),
      map((customers: Customer[]) => customers.length > 0 ? customers[0] : undefined)
    );
  }

  /**
   * Removes Customers.
   *
   * @param customerIds the IDs of the Customers to remove
   */
  public async removeCustomers(customerIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const customerId of customerIds) {
      batch.delete(this.customerCollection.doc(customerId).ref);
    }

    await batch.commit();
  }

  /**
   * Send verification code to Customer.
   *
   * @param customerId the ID of the Customer object to send the verification code to
   */
  public async sendVerificationCode(customerId: string): Promise<void> {
    const body: any = {
      customer_id: customerId
    };

    this.http.post(
      environment.firebaseEndpoint.sendVerificationCode,
      JSON.stringify(body)
    ).subscribe(() => {
      // ignore
    });
  }
}
