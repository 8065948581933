import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/environment';


/**
 * See https://medium.com/@markuretsky/extend-angular-datepipe-for-timezone-abbreviations-support-9b65fa0807fb
 */
@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe extends DatePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private translateService: TranslateService
  ) {
    super(locale);
  }

  transform(value: string | Date, format: string = 'mediumDate', timezone?: string, locale?: string, showTodayText: boolean = false): string {
    let result: string | undefined;

    if (typeof value === 'string' && value.startsWith('P')) {
      const duration: moment.Duration = moment.duration(value as string);
      result = duration.asMinutes() === 0
        ? this.translateService.instant('PIPE_MOMENT_DATE.ASAP')
        : this.translateService.instant('PIPE_MOMENT_DATE.IN_MINUTES', { count: duration.asMinutes() });
    } else {
      timezone = timezone || environment.menuApp.defaultTimezone;
      if (showTodayText && moment().tz(timezone).isSame(moment(value), 'days')) {
        result = this.translateService.instant('PIPE_MOMENT_DATE.TODAY');
      } else {
        const timezoneOffset = moment(value).tz(timezone).format('Z');
        result = super.transform(value, format, timezoneOffset);
      }
    }

    return result;
  }
}
