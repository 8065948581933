import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Order } from '../models/order';
import { OrderReceipt } from '../models/order-receipt';
import { environment } from '../../../environments/environment';


/**
 * Class providing access methods for orders.
 */
@Injectable({
  providedIn: 'root'
})
export class OrderAccess {

  private orderCollection: AngularFirestoreCollection<Order>;
  private orderReceiptCollection: AngularFirestoreCollection<OrderReceipt>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore,
    private http: HttpClient
  ) {
    this.orderCollection = afs.collection<Order>('orders');
    this.orderReceiptCollection = afs.collection<OrderReceipt>('orderReceipts');
  }

  /**
   * Returns all Orders of a location.
   *
   * @param locationId the ID of the location
   * @returns the found Orders, otherwise empty list
   */
  public getAllOrders(locationId: string): Observable<Order[]> {
    return this.afs.collection<Order>('orders', ref => ref.where('locationId', '==', locationId).orderBy('createdAt', 'desc'))
      .valueChanges().pipe(
        map((ordersJson) => {
          return ordersJson as Order[]; // plainToClass(Order, ordersJson as object[]);
        })
      );
  }

  /**
   * Returns all Orders of a location with fulfillments.
   *
   * @param locationId the ID of the location
   * @param fulfillmentTypes the orders with one of these specific fulfillment types
   * @param fromTime return orders newer than this time
   * @returns the found Orders, otherwise empty list
   */
  public getAllOrdersWithFulfillment(locationId?: string, fulfillmentTypes?: string[], fromTime?: string): Observable<Order[]> {
    return this.afs.collection<Order>('orders', ref => {
      let newRef = ref.orderBy('createdAt', 'desc');

      if (locationId !== undefined) {
        newRef = newRef.where('locationId', '==', locationId);
      }

      if (fromTime !== undefined) {
        newRef = newRef.where('createdAt', '>=', fromTime);
      }

      return newRef;
    }).valueChanges().pipe(
      map((ordersJson) => {
        const orders: Order[] = ordersJson as Order[]; // plainToClass(Order, ordersJson as object[]);
        return orders.filter((order: Order) => {
          let keep = order.fulfillments && order.fulfillments.length > 0;

          if (keep && fulfillmentTypes !== undefined) {
            keep = fulfillmentTypes.includes(order.fulfillments[0].type);
          }

          return keep;
        });
      })
    );
  }

  /**
   * Returns all Orders of an Event.
   *
   * @param eventId the ID of the Event
   * @returns the found Orders, otherwise empty list
   */
  public getAllOrdersOfEvent(eventId: string): Observable<Order[]> {
    return this.orderCollection.doc(eventId).collection<Order>('orders', ref => ref.where('eventId', '==', eventId).orderBy('createdAt', 'desc'))
      .valueChanges().pipe(
        map((ordersJson) => {
          return ordersJson as Order[]; // plainToClass(Order, ordersJson as object[]);
        })
      );
  }

  /**
   * Returns all Orders of a Customer.
   *
   * @param customerId the ID of the Customer
   * @returns the found Orders, otherwise empty list
   */
  public getOrdersOfCustomer(customerId: string): Observable<Order[]> {
    return this.afs.collection<Order>('orders', ref => ref.where('customerId', '==', customerId).orderBy('createdAt', 'desc'))
      .valueChanges().pipe(
        map((ordersJson) => {
          return ordersJson as Order[]; // plainToClass(Order, ordersJson as object[]);
        })
      );
  }

  /**
   * Returns all placed Orders (including COMPLETED, CANCELLED and DEACTIVATED orders).
   *
   * @param locationId the ID of the location
   * @returns the found Orders
   */
  public getPlacedOnlyOrders(locationId: string): Observable<Order[]> {
    return this.afs.collection<Order>('orders', ref => ref
      .where('locationId', '==', locationId)
      .where('status', '==', 'PLACED')
      .orderBy('createdAt', 'desc')
    ).valueChanges().pipe(
      map((ordersJson) => {
        return ordersJson as Order[]; // plainToClass(Order, ordersJson as object[]);
      })
    );
  }

  /**
   * Returns all placed Orders between two dates (including COMPLETED, CANCELLED and DEACTIVATED orders).
   *
   * @param accountId the ID of the account the orders belong to
   * @param locationId the ID of the location the orders belong to
   * @param from the from date in RFC 3339 format
   * @param to the to date in RFC 3339 format
   * @returns the found Orders
   */
  public getPlacedOrders(accountId?: string, locationId?: string, from?: string, to?: string): Observable<Order[]> {
    return this.afs.collection<Order>('orders', ref => {
      let newRef = ref.orderBy('placedAt', 'desc');

      if (accountId !== undefined) {
        newRef = newRef.where('accountId', '==', accountId);
      }

      if (locationId !== undefined) {
        newRef = newRef.where('locationId', '==', locationId);
      }

      if (from !== undefined) {
        newRef = newRef.where('placedAt', '>=', from);
      }

      if (to !== undefined) {
        newRef = newRef.where('placedAt', '<', to);
      }

      return newRef;
    }).valueChanges().pipe(
      map((ordersJson) => {
        return ordersJson as Order[]; // plainToClass(Order, ordersJson as object[]);
      })
    );
  }

  /**
   * Returns all placed and open Orders between two dates.
   *
   * @param accountId the ID of the account the orders belong to
   * @param from the from date in RFC 3339 format
   * @param to the to date in RFC 3339 format
   * @returns the found Orders
   */
  public getOpenOrders(accountId: string, from?: string, to?: string): Observable<Order[]> {
    return this.afs.collection<Order>('orders', ref => {
      let newRef = ref.orderBy('placedAt', 'desc');

      if (accountId !== undefined) {
        newRef = newRef.where('accountId', '==', accountId);
      }

      newRef = newRef.where('status', 'in', ['OPEN', 'PLACED', 'ACCEPTED']);

      if (from !== undefined) {
        newRef = newRef.where('placedAt', '>=', from);
      }

      if (to !== undefined) {
        newRef = newRef.where('placedAt', '<', to);
      }

      return newRef;
    }).valueChanges().pipe(
      map((ordersJson) => {
        return ordersJson as Order[]; // plainToClass(Order, ordersJson as object[]);
      })
    );
  }

  /**
   * Returns all completed Orders between two dates.
   *
   * @param accountId the ID of the account the orders belong to
   * @param from the from date in RFC 3339 format
   * @param to the to date in RFC 3339 format
   * @returns the found Orders
   */
  public getCompletedOrders(accountId: string, from: string, to: string): Observable<Order[]> {
    return this.afs.collection<Order>('orders', ref => ref
      .where('accountId', '==', accountId)
      .where('status', '==', 'COMPLETED')
      .where('completedAt', '>=', from)
      .where('completedAt', '<', to)
      .orderBy('completedAt')
    ).valueChanges().pipe(
        map((ordersJson) => {
          return ordersJson as Order[]; // plainToClass(Order, ordersJson as object[]);
        })
      );
  }

  /**
   * Returns an Order specified by the ID.
   *
   * @param orderId the Order ID
   * @returns the found Order, otherwise undefined
   */
  public getOrder(orderId: string): Observable<Order> {
    return this.orderCollection.doc(orderId).valueChanges().pipe(
      map((orderJson) => {
        return orderJson as Order; // plainToClass(Order, orderJson);
      })
    );
  }

  /**
   * Returns the Order specified by the cancellation code.
   *
   * @param cancellationCode the cancellation code
   * @returns the found Order, otherwise undefined
   */
  public getOrderByCancellationCode(cancellationCode: string): Observable<Order | undefined> {
    return this.afs.collection<Order>('orders', ref => ref.where('cancellationCode', '==', cancellationCode))
      .valueChanges().pipe(
        map((ordersJson) => {
          return ordersJson as Order[]; // plainToClass(Order, ordersJson as object[]);
        }),
        map((orders: Order[]) => orders.length > 0 ? orders[0] : undefined)
      );
  }

  /**
   * Add new Order to cloud.
   *
   * @param order the Order object to add
   * @return the id of the new Order
   */
  public async addOrder(order: Order): Promise<string> {
    if (!order.id) {
      order.id = this.afs.createId();
      order.createdAt = new Date().toISOString();
    }
    order.updatedAt = new Date().toISOString();

    await this.orderCollection.doc(order.id).set(classToPlain(order) as Order, { merge: true });

    return order.id;
  }

  /**
   * Updates the order of the specified event.
   *
   * @param order the order to update
   * @param eventId the event ID
   * @returns the updated order
   */
  public async updateOrder(order: Order, eventId?: string): Promise<string> {
    await this.orderCollection.doc(order.id).set(classToPlain(order) as Order, { merge: true });

    return order.id;
  }

  /**
   * Removes Orders.
   *
   * @param orderIds the IDs of the Orders to remove
   */
  public async removeOrders(orderIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const orderId of orderIds) {
      batch.delete(this.orderCollection.doc(orderId).ref);
    }

    await batch.commit();
  }

  /**
   * Changes status of Orders.
   *
   * @param orderIds the IDs of the Orders to change
   * @param status possible values are OPEN, COMPLETED, CANCELLED
   */
  public async setStatusOfOrders(orderIds: string[], status: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const orderId of orderIds) {
      batch.update(this.orderCollection.doc(orderId).ref, { status });
    }

    await batch.commit();
  }

  /**
   * Returns an OrderReceipt specified by the ID.
   *
   * @param orderReceiptId the OrderReceipt ID
   * @returns the found OrderReceipt, otherwise undefined
   */
  public getOrderReceipt(orderReceiptId: string): Observable<OrderReceipt> {
    return this.orderReceiptCollection.doc(orderReceiptId).valueChanges().pipe(
      map((orderReceiptJson) => {
        return orderReceiptJson as OrderReceipt; // plainToClass(OrderReceipt, orderReceiptJson);
      })
    );
  }

  /**
   * Add new OrderReceipt to cloud.
   *
   * @param orderReceipt the OrderReceipt object to add
   * @return the id of the new OrderReceipt
   */
  public async addOrderReceipt(orderReceipt: OrderReceipt): Promise<string> {
    if (!orderReceipt.id) {
      orderReceipt.id = this.afs.createId();
      orderReceipt.createdAt = new Date().toISOString();
    }
    orderReceipt.updatedAt = new Date().toISOString();

    await this.orderReceiptCollection.doc(orderReceipt.id).set(classToPlain(orderReceipt) as OrderReceipt, { merge: true });

    return orderReceipt.id;
  }

  /**
   * Removes OrderReceipts.
   *
   * @param orderReceiptIds the IDs of the OrderReceipts to remove
   */
  public async removeOrderReceipts(orderReceiptIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const orderReceiptId of orderReceiptIds) {
      batch.delete(this.orderReceiptCollection.doc(orderReceiptId).ref);
    }

    await batch.commit();
  }

  /**
   * Sends a confirmation reminder.
   *
   * @param orderId the ID of the order object to remind for
   */
  public async sendConfirmationReminder(orderId: string): Promise<void> {
    const body: any = {
      orderId
    };

    this.http.post(
      environment.firebaseEndpoint.orderConfirmationReminder,
      JSON.stringify(body)
    ).subscribe(() => {
      // ignore
    });
  }
}
