import { combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Account } from '../models/account';
import { AccountExtension } from '../models/account-extension';
import { AccountImage } from '../models/account-image';
import { AccountInvoice } from '../models/account-invoice';


/**
 * Class providing database methods for accounts.
 */
@Injectable({
  providedIn: 'root'
})
export class AccountAccess {

  private accountCollection: AngularFirestoreCollection<Account>;
  private accountExtensionCollection: AngularFirestoreCollection<AccountExtension>;
  private accountImageCollection: AngularFirestoreCollection<AccountImage>;
  private accountInvoiceCollection: AngularFirestoreCollection<AccountInvoice>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore
  ) {
    this.accountCollection = afs.collection<Account>('accounts');
    this.accountExtensionCollection = afs.collection<AccountExtension>('accountExtensions');
    this.accountImageCollection = afs.collection<AccountImage>('accountImages');
    this.accountInvoiceCollection = afs.collection<AccountInvoice>('accountInvoices');
  }

  /**
   * Returns all Accounts.
   *
   * @returns the found Accounts, otherwise empty list
   */
  public getAllAccounts(): Observable<Account[]> {
    return this.afs.collection<Account>('accounts', ref => ref.orderBy('name'))
      .valueChanges().pipe(
        map((accountsJson) => {
          return accountsJson as Account[]; // plainToClass(Account, accountsJson as object[]);
        })
      );
  }

  /**
   * Returns a list of accounts.
   *
   * @param accountIds the account IDs
   * @returns the found accounts, otherwise undefined
   */
  public getAccounts(accountIds: string[]): Observable<Account[]> {
    return of(accountIds).pipe(
      switchMap((ids: string[]) => {
        const observables: Observable<Account[]>[] = [];
        const chunkSize = 10;

        for (let i = 0, j = ids.length; i < j; i += chunkSize) {
          const chunk: string[] = ids.slice(i, i + chunkSize);

          if (chunk.length > 0) {
            observables.push(this.afs.collection<Account>('accounts', ref => ref.where('id', 'in', chunk))
              .valueChanges().pipe(
                map((accountsJson) => {
                  return accountsJson as Account[]; // plainToClass(Account, accountsJson as object[]);
                })
              ));
          }
        }

        return observables.length > 0 ? combineLatest(observables) : of([]);
      }),
      map((accountArrays: Account[][]) => {
        return [].concat.apply([], accountArrays);
      })
    );
  }

  /**
   * Adds an account.
   *
   * @param account the account to add
   * @returns the added account
   */
  public async addAccount(account: Account): Promise<Account> {
    if (!account.id) {
      account.id = this.afs.createId();
      account.createdAt = new Date().toISOString();
    }
    account.updatedAt = new Date().toISOString();

    await this.accountCollection.doc(account.id).set(classToPlain(account) as Account, { merge: true });

    return account;
  }

  /**
   * Updates an account.
   *
   * @param account the account to update
   * @returns the updated account
   */
  public async updateAccount(account: Account): Promise<Account> {
    await this.accountCollection.doc(account.id).set(classToPlain(account) as Account, { merge: true });

    return account;
  }

  /**
   * Removes Accounts.
   *
   * @param accountIds the IDs of the Accounts to remove
   */
  public async removeAccounts(accountIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    const imageIdsToRemove: string[] = [];
    const accounts: Account[] = await this.getAccounts(accountIds).pipe(first()).toPromise();
    for (const account of accounts) {
      if (account.imageIds) {
        imageIdsToRemove.push(...account.imageIds);
      }

      batch.delete(this.accountCollection.doc(account.id).ref);
    }
    await this.removeAccountImages(imageIdsToRemove);

    await batch.commit();
  }

  /**
   * Returns all AccountExtensions.
   *
   * @returns the found AccountExtensions, otherwise empty list
   */
  public getAllAccountExtensions(): Observable<AccountExtension[]> {
    return this.afs.collection<AccountExtension>('accountExtensions')
      .valueChanges().pipe(
        map((accountExtensionsJson) => {
          return accountExtensionsJson as AccountExtension[]; // plainToClass(AccountExtension, accountExtensionsJson as object[]);
        })
      );
  }

  /**
   * Returns a list of account extensions.
   *
   * @param accountIds the account IDs
   * @returns the found account extensions, otherwise undefined
   */
  public getAccountExtensions(accountIds: string[]): Observable<AccountExtension[]> {
    return of(accountIds).pipe(
      switchMap((ids: string[]) => {
        const observables: Observable<AccountExtension[]>[] = [];
        const chunkSize = 10;

        for (let i = 0, j = ids.length; i < j; i += chunkSize) {
          const chunk: string[] = ids.slice(i, i + chunkSize);

          if (chunk.length > 0) {
            observables.push(this.afs.collection<AccountExtension>('accountExtensions', ref => ref.where('id', 'in', chunk))
              .valueChanges().pipe(
                map((accountExtensionsJson) => {
                  return accountExtensionsJson as AccountExtension[]; // plainToClass(AccountExtension, accountExtensionsJson as object[]);
                })
              ));
          }
        }

        return observables.length > 0 ? combineLatest(observables) : of([]);
      }),
      map((accountArrays: Account[][]) => {
        return [].concat.apply([], accountArrays);
      })
    );
  }

  /**
   * Adds an account extension.
   *
   * @param accountExtension the account extension to add
   * @returns the added account extension
   */
  public async addAccountExtension(accountExtension: AccountExtension): Promise<AccountExtension> {
    if (!accountExtension.id) {
      accountExtension.id = this.afs.createId();
      accountExtension.createdAt = new Date().toISOString();
    }
    accountExtension.updatedAt = new Date().toISOString();

    await this.accountExtensionCollection.doc(accountExtension.id).set(classToPlain(accountExtension) as AccountExtension, { merge: true });

    return accountExtension;
  }

  /**
   * Updates an account extension.
   *
   * @param accountExtension the account extension to update
   * @returns the updated account extension
   */
  public async updateAccountExtension(accountExtension: AccountExtension): Promise<AccountExtension> {
    await this.accountExtensionCollection.doc(accountExtension.id).set(classToPlain(accountExtension) as AccountExtension, { merge: true });

    return accountExtension;
  }

  /**
   * Removes AccountExtensions.
   *
   * @param accountIds the IDs of the AccountExtensions to remove
   */
  public async removeAccountExtensions(accountIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    const accountExtensions: AccountExtension[] = await this.getAccountExtensions(accountIds).pipe(first()).toPromise();
    for (const accountExtension of accountExtensions) {
      batch.delete(this.accountExtensionCollection.doc(accountExtension.id).ref);
    }

    await batch.commit();
  }

  /**
   * Add new AccountImage to cloud.
   *
   * @param accountImage the AccountImage object to add
   * @return the id of the new AccountImage
   */
  public async addAccountImage(accountImage: AccountImage): Promise<string> {
    if (!accountImage.id) {
      accountImage.id = this.afs.createId();
      accountImage.createdAt = new Date().toISOString();
    }
    accountImage.updatedAt = new Date().toISOString();

    await this.accountImageCollection.doc(accountImage.id).set(classToPlain(accountImage) as AccountImage, { merge: true });

    return accountImage.id;
  }

  /**
   * Returns the AccountImage specified by the ID.
   *
   * @param accountImageId the AccountImage ID
   * @returns the found accountImage, otherwise undefined
   */
  public getAccountImage(accountImageId: string): Observable<AccountImage> {
    return this.accountImageCollection.doc(accountImageId).valueChanges().pipe(
      map((accountImageJson) => {
        return accountImageJson as AccountImage; // plainToClass(AccountImage, accountImageJson);
      })
    );
  }

  /**
   * Removes AccountImages.
   *
   * @param accountImageIds the IDs of the AccountImages to remove
   */
  public async removeAccountImages(accountImageIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const accountImageId of accountImageIds) {
      batch.delete(this.accountImageCollection.doc(accountImageId).ref);
    }

    await batch.commit();
  }

  /**
   * Returns an AccountInvoice specified by the ID.
   *
   * @param accountInvoiceId the AccountInvoice ID
   * @returns the found AccountInvoice, otherwise undefined
   */
  public getAccountInvoice(accountInvoiceId: string): Observable<AccountInvoice> {
    return this.accountInvoiceCollection.doc(accountInvoiceId).valueChanges().pipe(
      map((accountInvoiceJson) => {
        return accountInvoiceJson as AccountInvoice; // plainToClass(AccountInvoice, accountInvoiceJson);
      })
    );
  }

  /**
   * Add new AccountInvoice to cloud.
   *
   * @param accountInvoice the AccountInvoice object to add
   * @return the id of the new AccountInvoice
   */
  public async addAccountInvoice(accountInvoice: AccountInvoice): Promise<string> {
    if (!accountInvoice.id) {
      accountInvoice.id = this.afs.createId();
      accountInvoice.createdAt = new Date().toISOString();
    }
    accountInvoice.updatedAt = new Date().toISOString();

    await this.accountInvoiceCollection.doc(accountInvoice.id).set(classToPlain(accountInvoice) as AccountInvoice, { merge: true });

    return accountInvoice.id;
  }

  /**
   * Removes AccountInvoices.
   *
   * @param accountInvoiceIds the IDs of the AccountInvoices to remove
   */
  public async removeAccountInvoices(accountInvoiceIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const accountInvoiceId of accountInvoiceIds) {
      batch.delete(this.accountInvoiceCollection.doc(accountInvoiceId).ref);
    }

    await batch.commit();
  }
}
