import { Address } from './address';
import { Money } from './money';
import { OrderServiceTypeConstants } from './order-service';
import { ReservationServiceTypeConstants } from './reservation-service';


/**
 * Represents a rule for delivering.
 */
export class DeliveryRule {

  /**
   * Indicates how the rule will be triggered.
   *
   * DISTANCE_LINEAR_METERS: Apply the rule if address to deliver to is within the linear distance in meters. (default)
   * DISTANCE_METERS: Apply the rule if address to deliver to is within the driving distance in meters.
   * DISTANCE_MINUTES: Apply the rule if address to deliver to is within the driving distance in minutes.
   * POSTAL_CODE: Apply the rule if the postal code of the address to deliver to is one of the supported postal codes.
   * COUNTRY: Apply the rule if the country code of the address to deliver to is one of the supported country codes.
   */
  public triggerType = 'DISTANCE_LINEAR_METERS';
  /** Minimum order value. */
  public minOrderValue: Money;
  /** Shipping. */
  public shipping: Money;
  /** The max. distance in meters or minutes depending on the trigger type. */
  public distance?: number;
  /** The catchment area for delivery as comma separated postal codes. */
  public postalCodes?: string;
  /** The supported countries for delivery as comma separated codes, in ISO 3166-1-alpha-2 format. */
  public countries?: string;
}

/**
 * Represents the hours of operation for a business location.
 */
export class BusinessHours {
  /** The list of time periods during which the business is open. There may be at most 10 periods per day. */
  public periods: BusinessHoursPeriod[];
}

/**
 * Represents a period of time during which a business location is open.
 */
export class BusinessHoursPeriod {
  /** The day of week for this time period, with Sunday as 0 and Saturday as 6. */
  public dayOfWeek: string;

  /** The start time of a business hours period, specified in local time using partial-time RFC3339 format. */
  public startLocalTime: string;

  /** The end time of a business hours period, specified in local time using partial-time RFC3339 format. */
  public endLocalTime: string;
}

/**
 * Represents a blocked period of time during which a business location is closed temporarily.
 */
export class BlockedPeriod {
  /** The start time of a blocked period, in RFC 3339 format. */
  public startTime: string;

  /** The end time of a blocked period, in RFC 3339 format. */
  public endTime: string;

  /**
   * Specifies the types of service that the blocked period applies to.
   * Undefined for not restricted.
   */
  public restrictedToServiceTypes?: (OrderServiceTypeConstants | ReservationServiceTypeConstants)[];
}

/**
 * Represents one of a business's locations.
 */
export class Location {

  /** The unique ID of this object. */
  public id: string;
  /** The unique (consecutive) ID of this object. */
  public consecutiveId?: number;
  /** The account's unique ID the object belongs to. */
  public accountId: string;

  /** The location's name. Location names are set by the account owner and displayed in the dashboard as the location's nickname. */
  public name: string;

  /** The location's business_name which is shown to its customers. For example, this is the name printed on its customer's receipts. */
  public businessName: string;

  public description?: string;

  /** The location's service provider. 'internal' for platform (default), 'external' for others. */
  public provider?: string;
  /** URL to an external restaurant management system. */
  public providerExternalUrl?: string;
  /** URL to an external restaurant management system specific for dine-in. */
  public providerExternalDineInUrl?: string;

  /** Comma separated keywords. */
  public keywords?: string;

  /** The location's visibility, e.g. 'hidden', 'visible'. */
  public visibility = 'visible';

  /**
   * The location's type, as set by the account owner in the Square dashboard. Typically used to indicate whether or not the
   * location object represents a physical space like a building or mall space. 'PHYSICAL' and 'MOBILE' are possible values.
   */
  public type: string;

  /** The location's physical address. */
  public address?: Address;

  /** The IANA Timezone Database identifier for the location's timezone. */
  public timezone: string;

  /** Indicates which Square features are enabled for the location. See LocationCapability for possible values. */
  public capabilities?: string[];

  /** The location's status See LocationStatus for possible values. */
  public status: string;

  /** The location's country, in ISO 3166-1-alpha-2 format. See Country for possible values. */
  public country: string;

  /** The language associated with the location in BCP 47 format. */
  public countryCode: string;

  /**
   * The currency used for all transactions at this location, specified in ISO 4217 format. For example, the currency for a location
   * processing transactions in the United States is 'USD'. See Currency for possible values.
   */
  public currency: string;

  /** The location's email address. */
  public email?: string;

  /** The location's phone number. */
  public phoneNumber?: string;

  /** The location's imprint. */
  public imprint?: string;

  /**
   * The location's Twitter URL.
   * Default: none; only exists if explicitly set.
   */
  public twitterUrl?: string;

  /**
   * The location's Instagram URL.
   * Default: none; only exists if explicitly set.
   */
  public instagramUrl?: string;

  /**
   * The location's Facebook URL.
   * Default: none; only exists if explicitly set.
   */
  public facebookUrl?: string;

  /**
   * The location's website, as set by the account owner in the Square dashboard.
   * Default: none; only exists if explicitly set.
   */
  public websiteUrl?: string;

  /**
   * The hours of operation for a business location.
   * Default: none; only exists if explicitly set.
   */
  public businessHours?: BusinessHours;

  /** The blocked periods of time during which a business location is closed temporarily. */
  public blockedPeriods?: BlockedPeriod[];

  /** Indicates whether the restaurant reservation module should be enabled. */
  public useReservations = true;
  /** The type of reservation system to use, e.g. 'internal', 'external' */
  public reservationType = 'internal';
  /** The services connected to the reservation like Reserve with Google (GOOGLE_RESERVE), comma-separated. */
  public reservationServices?: string;
  /** INTERNAL: Maximum duration of stay in minutes, optional. */
  public reservationDurationOfStay?: number;
  /** INTERNAL: Comma separated email addresses. */
  public reservationEmails?: string;
  /** INTERNAL: Text related to this service. */
  public reservationNote?: string;
  /** INTERNAL: Maximum number of persons of a reservation. */
  public reservationMaxSize = 8;
  /** INTERNAL: Maximum time in advance for a reservation (in days). */
  public reservationMaxTimeInAdvance = 84;
  /** DEPRECATED: INTERNAL: Maximum time in advance for a reservation (in months). */
  public reservationMaxTimeInMonth = 3;
  /** INTERNAL: The time in minutes to wait for the next possible reservation. */
  public reservationStartOffset = 0;
  /** INTERNAL: Indicates whether the phone number of the restaurant should be shown in reservation dialog. */
  public reservationShowPhoneNumber = true;
  /** EXTERNAL: URL to an external reservation system. */
  public reservationExternalUrl?: string;
  /** Phone number used for notifications. */
  public reservationPhoneNumber?: string;
  /** DEPRECATED: Indicates whether text message is enabled. */
  public reservationTextMessage = false;

  /** The services connected to the ordering like OrderDirect (ORDERDIRECT), comma-separated. */
  public orderingServices?: string;
  /** Comma-separated email addresses. */
  public orderingEmails?: string;
  /** Phone number used for notifications. */
  public orderingPhoneNumber?: string;
  /** Comma-separated diets IDs. */
  public orderingSupportedDiets?: string;

  /** Temporarily in use. */
  public orderingOrderdirectVisibility = 'hidden';

  /** DEPRECATED: Indicates whether text message is enabled. */
  public orderingTextMessage = false;
  /** DEPRECATED: Comma-separated payment method types, e.g. 'ON_SITE', 'ON_SITE:EC', 'CARD'. */
  public orderingPaymentMethodTypes?: string;
  /** DEPRECATED: Enable sending order receipts for specific fulfillment types, e.g. DINE_IN, PICKUP, SHIPMENT as a comma-separated list. */
  public orderingSendReceipts?: string;
  /** DEPRECATED: Enable tipping for specific fulfillment types, e.g. DINE_IN, PICKUP, SHIPMENT as a comma-separated list. */
  public orderingUseTips?: string;

  /** DEPRECATED: Indicates whether ordering is enabled for dine-in. */
  public orderingDineIn = false;
  /** DEPRECATED: Text related to dine-in. */
  public orderingDineInText?: string;
  /** DEPRECATED: Comma-separated payment method types overriding the main payment methods. */
  public orderingDineInPaymentMethodTypes?: string;
  /** DEPRECATED: Indicates whether asap-ordering is enabled. */
  public orderingDineInAsap = false;
  /** DEPRECATED: Indicates whether pre-ordering is enabled. */
  public orderingDineInPreOrder = false;
  /** DEPRECATED: Maximum time in advance for an order (in days). */
  public orderingDineInPreOrderMaxOffset = 0;
  /** DEPRECATED: Comma-separated input fields. */
  public orderingDineInInputFields?: string;
  /** DEPRECATED: Indicates whether ordering is enabled for pickup. */
  public orderingPickup = false;
  /** DEPRECATED: Text related to pick-up. */
  public orderingPickupText?: string;
  /** DEPRECATED: PICKUP: The time in minutes to wait for the next possible order for pick-up. */
  public orderingPickupStartOffset = 0;
  /** DEPRECATED: Indicates whether asap-ordering is enabled. */
  public orderingPickupAsap = false;
  /** DEPRECATED: Indicates whether pre-ordering is enabled. */
  public orderingPickupPreOrder = false;
  /** DEPRECATED: Maximum time in advance for an order (in days). */
  public orderingPickupPreOrderMaxOffset = 0;
  /** DEPRECATED: Comma-separated input fields. */
  public orderingPickupInputFields?: string;
  /** DEPRECATED: Indicates whether ordering is enabled for delivery. */
  public orderingDelivery = false;
  /** DEPRECATED: Text related to delivery. */
  public orderingDeliveryText?: string;
  /** DEPRECATED: DELIVERY: The time in minutes to wait for the next possible order for delivery. */
  public orderingDeliveryStartOffset = 0;
  /** DEPRECATED: Indicates whether asap-ordering is enabled. */
  public orderingDeliveryAsap = false;
  /** DEPRECATED: Indicates whether pre-ordering is enabled. */
  public orderingDeliveryPreOrder = false;
  /** DEPRECATED: Maximum time in advance for an order (in days). */
  public orderingDeliveryPreOrderMaxOffset = 0;
  /** DEPRECATED: Comma-separated input fields. */
  public orderingDeliveryInputFields?: string;
  /** DEPRECATED: DELIVERY: The rules for delivering. */
  public orderingDeliveryRules?: DeliveryRule[];
  /** DEPRECATED: DELIVERY: Minimum order value. */
  public orderingDeliveryMinOrderValue: Money;
  /** DEPRECATED: DELIVERY: Shipping. */
  public orderingDeliveryShipping: Money;
  /** DEPRECATED: DELIVERY: The catchment area for delivery as comma-separated postal codes. */
  public orderingDeliveryArea?: string;

  /** DEPRECATED: Indicates whether to use own special times for delivery. If false then use business hours of location. */
  public useSpecialDeliveryTimes = false;
  /**
   * DEPRECATED:
   * The hours of delivery for a business location.
   * Default: none; only exists if explicitly set.
   */
  public orderingDeliveryTimes?: BusinessHours;

  /** Identifies the LocationImages attached to this Location. */
  public imageIds?: string[];

  /** Indicates whether the restaurant check-in (due to COVID pandemic) module should be enabled. */
  public useCheckIns = false;
  /** Approximate duration of stay, in minutes. */
  public checkInDurationOfStay = 180;
  /** The time after which the check-in record is deleted, in days. */
  public checkInDurationOfStorage = 28;
  /** All of these contact options should be entered for a check-in. Comma-separated values of PHONE, EMAIL, ADDRESS. */
  public checkInContactAll = '';
  /** At least one of these contact options should be entered for a check-in. Comma-separated values of PHONE, EMAIL, ADDRESS. */
  public checkInContactAny = 'PHONE,ADDRESS';
  /** The necessity type of place input, e.g. NONE, MANDATORY, OPTIONAL */
  public checkInNecessityPlace = 'OPTIONAL';
  /** The necessity type of place input, e.g. NONE, MANDATORY, OPTIONAL */
  public checkInNecessityAccessRequirement = 'OPTIONAL';

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;

  public preview: string;
  public rating: number;
  public reviews: number;
  public cuisine: string;
  public parking: string;
  public dressCode: string;
  public paymentMethods: string;

  public refs: { name: string, ref: string }[];

  /**
   * A map of key-value pairs that you can attach to this object.
   * This can be useful for storing additional information about the object in a structured format.
   */
  public metadata?: { [key: string]: string };

  /** The unique name of the location as part of the URL. */
  public linkName: string;

  /** The unique name of the location as part of the URL. */
  public domain?: string;
}
