import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { CatalogMenu } from '../../models/catalog-menu';
import { CatalogCategory } from '../../models/catalog-category';
import { CatalogItem } from '../../models/catalog-item';


class CatalogNode {
  children: CatalogNode[];
  id: string;
  name: string;
}

class CatalogFlatNode {
  id: string;
  name: string;
  selectedManually = false;
  disabled = false;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-catalog-select',
  templateUrl: './catalog-select.component.html',
  styleUrls: ['./catalog-select.component.scss'],
})
export class CatalogSelectComponent implements OnInit {

  @Input() header?: string;
  @Input() subHeader?: string;
  @Input() message?: string;
  @Input() next?: string;
  @Input() accountId: string;
  @Input() catalogMenus: CatalogMenu[];
  @Input() catalogItems: CatalogItem[];
  @Input() selectedCatalogIds?: string[];

  public ready = false;

  public treeControl: FlatTreeControl<CatalogFlatNode>;
  public treeFlattener: MatTreeFlattener<CatalogNode, CatalogFlatNode>;
  public dataSource: MatTreeFlatDataSource<CatalogNode, CatalogFlatNode>;
  /** The selection for checklist */
  public checklistSelection = new SelectionModel<CatalogFlatNode>(true);

  /** Map from flat node to nested node. This helps us finding the nested node to be modified. */
  private flatNodeMap = new Map<CatalogFlatNode, CatalogNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection. */
  private nestedNodeMap = new Map<CatalogNode, CatalogFlatNode>();

  private getLevel = (node: CatalogFlatNode) => node.level;
  private isExpandable = (node: CatalogFlatNode) => node.expandable;
  private getChildren = (node: CatalogNode): CatalogNode[] => node.children;
  public hasChild = (_: number, nodeData: CatalogFlatNode) => nodeData.expandable;
  /** Transformer to convert nested node to flat node. Record the nodes in maps for later use. */
  private transformer = (node: CatalogNode, level: number) => {
    const existingFlatNode: CatalogFlatNode = this.nestedNodeMap.get(node);
    const flatNode = existingFlatNode && existingFlatNode.id === node.id ? existingFlatNode : new CatalogFlatNode();
    flatNode.id = node.id;
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  constructor(
    protected modalController: ModalController,
    protected translateService: TranslateService
  ) {
  }

  async ngOnInit() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<CatalogFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.dataSource.data = this.buildTree(this.catalogMenus, this.catalogItems);
    this.treeControl.expand(this.treeControl.dataNodes[0]);
    if (this.selectedCatalogIds) {
      this.treeControl.dataNodes.forEach((node: CatalogFlatNode) => {
        if (this.selectedCatalogIds.includes(node.id)) {
          node.expandable ? this.catalogSelectionToggle(node) : this.catalogLeafSelectionToggle(node);
        }
      });
    }

    this.ready = true;
  }

  /**
   * Build the structure tree containing CatalogMenus, CatalogCategories and CatalogItems.
   * The return value is the list of `CatalogNode`.
   */
  private buildTree(catalogMenus: CatalogMenu[], catalogItems: CatalogItem[]): CatalogNode[] {
    const tree: CatalogNode[] = [];

    // root node representing all items
    const allNode = new CatalogNode();
    allNode.id = '*';
    allNode.name = this.translateService.instant('CATALOG_SELECT_DIALOG.SELECT_ALL');
    allNode.children = [];
    tree.push(allNode);

    const categoryItemMap: Map<string, CatalogItem[]> = new Map();
    catalogItems.forEach((item: CatalogItem) => {
      const categoryId: string = item.categoryId;

      if (categoryId) {
        if (!categoryItemMap.has(categoryId)) {
          categoryItemMap.set(categoryId, []);
        }

        categoryItemMap.get(categoryId).push(item);
      }
    });

    catalogMenus.forEach((catalogMenu: CatalogMenu) => {
      const menuNode = new CatalogNode();
      menuNode.id = catalogMenu.id;
      menuNode.name = catalogMenu.name;
      allNode.children.push(menuNode);

      if (catalogMenu.categories && catalogMenu.categories.length > 0) {
        menuNode.children = [];

        catalogMenu.categories.forEach((catalogCategory: CatalogCategory) => {
          const categoryNode = new CatalogNode();
          categoryNode.id = catalogCategory.id;
          categoryNode.name = catalogCategory.name;
          menuNode.children.push(categoryNode);

          if (categoryItemMap.has(catalogCategory.id)) {
            categoryNode.children = [];

            categoryItemMap.get(catalogCategory.id).forEach((catalogItem: CatalogItem) => {
              const itemNode = new CatalogNode();
              itemNode.id = catalogItem.id;
              itemNode.name = catalogItem.name;
              categoryNode.children.push(itemNode);
            });
          }
        });
      }
    });

    return tree;
  }

  /** Whether all the descendants of the node are selected. */
  public descendantsAllSelected(node: CatalogFlatNode): boolean {
    const descendants: CatalogFlatNode[] = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected. */
  public descendantsPartiallySelected(node: CatalogFlatNode): boolean {
    const descendants: CatalogFlatNode[] = this.treeControl.getDescendants(node);
    const result = descendants.some((child: CatalogFlatNode) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the Catalog object selection. Select/deselect all the descendants node. */
  public catalogSelectionToggle(node: CatalogFlatNode): void {
    this.checklistSelection.toggle(node);
    node.selectedManually = this.checklistSelection.isSelected(node);

    // update children
    const descendants: CatalogFlatNode[] = this.treeControl.getDescendants(node);
    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.select(...descendants);
      descendants.forEach((descendant: CatalogFlatNode) => {
        descendant.disabled = true;
        descendant.selectedManually = false;
      });
    } else {
      this.checklistSelection.deselect(...descendants);
      descendants.forEach((descendant: CatalogFlatNode) => {
        descendant.disabled = false;
        descendant.selectedManually = false;
      });
    }

    // Force update for the parent
    descendants.every((child: CatalogFlatNode) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf Catalog selection. Check all the parents to see if they changed. */
  public catalogLeafSelectionToggle(node: CatalogFlatNode): void {
    this.checklistSelection.toggle(node);
    node.selectedManually = this.checklistSelection.isSelected(node);

    this.checkAllParentsSelection(node);
  }

  /** Checks all the parents when a leaf node is selected/unselected. */
  private checkAllParentsSelection(node: CatalogFlatNode): void {
    let parent: CatalogFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly. */
  private checkRootNodeSelection(node: CatalogFlatNode): void {
    const nodeSelected: boolean = this.checklistSelection.isSelected(node);
    const descendants: CatalogFlatNode[] = this.treeControl.getDescendants(node);
    const descAllSelected: boolean = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /** Get the parent node of a node. */
  private getParentNode(node: CatalogFlatNode): CatalogFlatNode | null {
    const currentLevel: number = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex: number = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode: CatalogFlatNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  public async closeModal(cancel: boolean, role: string): Promise<void> {
    const catalogObjectIds: string[] = this.checklistSelection.selected
      .filter((node: CatalogFlatNode) => !node.disabled && node.selectedManually)
      .map((node: CatalogFlatNode) => node.id);
    await this.modalController.dismiss(cancel ? undefined : catalogObjectIds, role);
  }
}
