import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { Storage } from '@ionic/storage';

import { User } from '../models/user';
import { Account } from '../models/account';
import { AccountExtension } from '../models/account-extension';


/**
 * Class providing information within the current session.
 */
@Injectable({
  providedIn: 'root'
})
export class SessionManager {

  protected readySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  protected currentUserSubject: ReplaySubject<User | undefined> = new ReplaySubject(1);
  protected currentAccountSubject: ReplaySubject<[Account, AccountExtension] | undefined> = new ReplaySubject(1);
  protected accountsSubject: ReplaySubject<Account[] | undefined> = new ReplaySubject(1);
  protected limitLocationsSubject: ReplaySubject<number> = new ReplaySubject(1);
  protected limitMenusSubject: ReplaySubject<number | null> = new ReplaySubject(1);
  protected limitCategoriesSubject: ReplaySubject<number | null> = new ReplaySubject(1);
  protected limitItemsSubject: ReplaySubject<number | null> = new ReplaySubject(1);
  protected valueMap: Map<string, ReplaySubject<string | null>> = new Map();

  public readonly CURRENT_ACCOUNT_ID = 'currentAccountId';
  public readonly CURRENT_LOCATION_ID = 'currentLocationId';
  public readonly PREFERRED_LANGUAGE = 'preferredLanguage';
  public readonly COOKIE_CONSENT = 'cookieConsent';
  public readonly RESERVATION_ALERT_REPEAT = 'reservationAlertRepeat';
  public readonly ORDER_ALERT_REPEAT = 'orderAlertRepeat';
  public readonly ORDER_PRINT = 'orderPrint';
  public readonly ORDER_SORT = 'orderSort';

  /**
   * The default constructor.
   */
  constructor(
    private storage: Storage
  ) {
    this.currentUserSubject.next(undefined);
    this.currentAccountSubject.next([undefined, undefined]);
    this.accountsSubject.next([]);
    this.limitLocationsSubject.next(0);
    this.limitMenusSubject.next(0);
    this.limitCategoriesSubject.next(0);
    this.limitItemsSubject.next(0);
  }

  /**
   * Emit that all data is set in the SessionManager.
   */
  public setReady(): void {
    this.readySubject.next(true);
    this.readySubject.complete();
  }

  /**
   * Indicates whether SessionManager is ready.
   *
   * @returns true if SessionManager is ready
   */
  public isReady(): Observable<boolean> {
    return this.readySubject.asObservable();
  }

  /**
   * Sets current User.
   *
   * @param user the User to set
   */
  public setCurrentUser(user: User | undefined): void {
    this.currentUserSubject.next(user);
  }

  /**
   * Returns the current User.
   *
   * @returns the current User, otherwise undefined if not existent
   */
  public getCurrentUser(): Observable<User | undefined> {
    return this.currentUserSubject.asObservable();
  }

  /**
   * Sets current Account.
   *
   * @param account the Account to set
   */
  public setCurrentAccount(account: Account, accountExt: AccountExtension): void {
    this.currentAccountSubject.next([account, accountExt]);
  }

  /**
   * Returns current Account selected by the user.
   *
   * @returns the current Account, otherwise undefined if not existent
   */
  public getCurrentAccount(): Observable<[Account, AccountExtension] | undefined> {
    return this.currentAccountSubject.asObservable();
  }

  /**
   * Sets Accounts belonging to the current user.
   *
   * @param accounts the Accounts to set
   */
  public setAccounts(accounts: Account[]): void {
    this.accountsSubject.next(accounts);
  }

  /**
   * Returns Accounts belonging to the current user.
   *
   * @returns the Accounts, otherwise empty
   */
  public getAccounts(): Observable<Account[]> {
    return this.accountsSubject.asObservable();
  }

  /**
   * Sets the maximum number of allowed locations.
   *
   * @param limitLocations the maximum number of allowed locations
   */
  public setLimitLocations(limitLocations: number): void {
    this.limitLocationsSubject.next(limitLocations);
  }

  /**
   * Returns the maximum number of allowed locations.
   *
   * @returns the maximum number of allowed locations
   */
  public getLimitLocations(): Observable<number> {
    return this.limitLocationsSubject.asObservable();
  }

  /**
   * Sets the maximum number of allowed menus.
   *
   * @param limitMenus the maximum number of allowed menus, null if no limit
   */
  public setLimitMenus(limitMenus: number | null): void {
    this.limitMenusSubject.next(limitMenus);
  }

  /**
   * Returns the maximum number of allowed menus.
   *
   * @returns the maximum number of allowed menus, null if no limit
   */
  public getLimitMenus(): Observable<number | null> {
    return this.limitMenusSubject.asObservable();
  }

  /**
   * Sets the maximum number of allowed categories.
   *
   * @param limitCategories the maximum number of allowed categories, null if no limit
   */
  public setLimitCategories(limitCategories: number | null): void {
    this.limitCategoriesSubject.next(limitCategories);
  }

  /**
   * Returns the maximum number of allowed categories.
   *
   * @returns the maximum number of allowed categories, null if no limit
   */
  public getLimitCategories(): Observable<number | null> {
    return this.limitCategoriesSubject.asObservable();
  }

  /**
   * Sets the maximum number of allowed items.
   *
   * @param limitItems the maximum number of allowed items, null if no limit
   */
  public setLimitItems(limitItems: number | null): void {
    this.limitItemsSubject.next(limitItems);
  }

  /**
   * Returns the maximum number of allowed items.
   *
   * @returns the maximum number of allowed items, null if no limit
   */
  public getLimitItems(): Observable<number | null> {
    return this.limitItemsSubject.asObservable();
  }

  public async setValue(key: string, value: any | null, persistent: boolean = false): Promise<void> {
    if (!this.valueMap.has(key)) {
      this.valueMap.set(key, new ReplaySubject(1));
    }

    try {
      if (persistent) {
        await this.storage.set(key, value);
      } else {
        await this.storage.remove(key);
      }
    } catch (e) {
      // ignore
    }

    this.valueMap.get(key).next(value);
  }

  public getValue(key: string): Observable<any | null> {
    if (!this.valueMap.has(key)) {
      this.valueMap.set(key, new ReplaySubject(1));

      this.storage.get(key).then((value: any | null) => {
        this.valueMap.get(key).next(value);
      });
    }

    return this.valueMap.get(key).asObservable();
  }

  public async removeValue(key: string): Promise<void> {
    if (this.valueMap.has(key)) {
      this.valueMap.get(key).next(null);
    }

    await this.storage.remove(key);
  }
}
