import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PricePlan } from '../models/price-plan';
import { PlanSubscription } from '../models/plan-subscription';
import { PlanAccess } from '../access/plan-access.service';


/**
 * Class providing management methods for pricing plans.
 */
@Injectable({
  providedIn: 'root'
})
export class PlanManager {

  /**
   * The default constructor.
   */
  constructor(
    private planAccess: PlanAccess
  ) {
  }

  /**
   * Add new PricePlan to cloud.
   *
   * @param pricePlan the PricePlan object to add
   * @return the id of the new PricePlan
   */
  public async addPricePlan(pricePlan: PricePlan): Promise<string> {
    return this.planAccess.addPricePlan(pricePlan);
  }

  /**
   * Returns all PricePlans.
   *
   * @returns the found PricePlans, otherwise empty list
   */
  public getAllPricePlans(): Observable<PricePlan[]> {
    return this.planAccess.getAllPricePlans();
  }

  /**
   * Returns the PricePlan specified by the ID.
   *
   * @param pricePlanId the PricePlan ID
   * @returns the found PricePlan, otherwise undefined
   */
  public getPricePlan(pricePlanId: string): Observable<PricePlan> {
    return this.planAccess.getPricePlan(pricePlanId);
  }

  /**
   * Removes PricePlans.
   *
   * @param pricePlanIds the IDs of the PricePlans to remove
   */
  public async removePricePlans(pricePlanIds: string[]): Promise<void> {
    return this.planAccess.removePricePlans(pricePlanIds);
  }

  /**
   * Changes visibility of PricePlans.
   *
   * @param pricePlanIds the IDs of the PricePlans to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfPricePlans(pricePlanIds: string[], visible: string): Promise<void> {
    return this.planAccess.setVisibilityOfPricePlans(pricePlanIds, visible);
  }

  /**
   * Add new PlanSubscription to cloud.
   *
   * @param planSubscription the PlanSubscription object to add
   * @return the id of the new PlanSubscription
   */
  public async addPlanSubscription(planSubscription: PlanSubscription): Promise<string> {
    return this.planAccess.addPlanSubscription(planSubscription);
  }

  /**
   * Returns all PlanSubscriptions of an account.
   *
   * @param accountId the ID of the account
   * @returns the found PlanSubscriptions, otherwise empty list
   */
  public getAllPlanSubscriptions(accountId: string): Observable<PlanSubscription[]> {
    return this.planAccess.getAllPlanSubscriptions(accountId);
  }

  /**
   * Returns the PlanSubscription specified by the ID.
   *
   * @param planSubscriptionId the PlanSubscription ID
   * @returns the found PlanSubscription, otherwise undefined
   */
  public getPlanSubscription(planSubscriptionId: string): Observable<PlanSubscription> {
    return this.planAccess.getPlanSubscription(planSubscriptionId);
  }

  /**
   * Removes PlanSubscriptions.
   *
   * @param planSubscriptionIds the IDs of the PlanSubscriptions to remove
   */
  public async removePlanSubscriptions(planSubscriptionIds: string[]): Promise<void> {
    return this.planAccess.removePlanSubscriptions(planSubscriptionIds);
  }
}
