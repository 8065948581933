import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { classToPlain } from 'class-transformer';

import { Event } from '../models/event';
import { User } from '../models/user';
import { EventTemplate } from '../models/event-template';


/**
 * Class providing access methods to the database.
 */
@Injectable({
  providedIn: 'root'
})
export class DatabaseAccess {

  private userCollection: AngularFirestoreCollection<User>;
  private eventCollection: AngularFirestoreCollection<Event>;
  private eventTemplateCollection: AngularFirestoreCollection<EventTemplate>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore
  ) {
    this.userCollection = afs.collection<User>('users');
    this.eventCollection = afs.collection<Event>('events');
    this.eventTemplateCollection = afs.collection<EventTemplate>('eventTemplates');
  }

  /**
   * Returns events of a user.
   *
   * @param userId the user ID
   * @returns the found events, otherwise empty list
   */
  public getEventsOfUser(userId: string): Observable<Event[]> {
    return this.afs.collection<Event>('events', ref => ref.where('memberIds', 'array-contains', userId).orderBy('createdAt', 'desc'))
      .valueChanges().pipe(
        map((eventsJson) => {
          return eventsJson as Event[]; // plainToClass(Event, eventsJson as object[]);
        })
      );
  }

  /**
   * Returns an event.
   *
   * @param eventId the event ID
   * @returns the found event, otherwise undefined
   */
  public getEvent(eventId: string): Observable<Event | undefined> {
    return this.eventCollection.doc(eventId).valueChanges().pipe(
      map((eventJson) => {
        return eventJson as Event; // plainToClass(Event, eventJson);
      })
    );
  }

  /**
   * Adds an event.
   *
   * @param event the event to add
   * @returns the added event
   */
  public async addEvent(event: Event): Promise<Event> {
    if (!event.id) {
      event.id = this.afs.createId();
      event.createdAt = new Date().toISOString();
    }
    event.updatedAt = new Date().toISOString();

    await this.eventCollection.doc(event.id).set(classToPlain(event) as Event, { merge: true });

    // START: to move to cloud
    const user: User | null = await this.getUser(event.userId).pipe(first()).toPromise();
    user.eventIds.push(event.id);
    await this.updateUser(user);
    // END

    return event;
  }

  /**
   * Updates an event.
   *
   * @param event the event to update
   * @returns the updated event
   */
  public async updateEvent(event: Event): Promise<Event> {
    await this.eventCollection.doc(event.id).set(classToPlain(event) as Event, { merge: true });

    return event;
  }

  /**
   * Removes an event.
   *
   * @param eventId the ID of the event to remove
   */
  public async removeEvent(eventId: string): Promise<void> {
    await this.eventCollection.doc(eventId).delete();
  }

  /**
   * Returns an event template.
   *
   * @param templateId the event template ID
   * @returns the found event template, otherwise undefined
   */
  public getEventTemplate(templateId: string): Observable<EventTemplate | undefined> {
    return this.eventTemplateCollection.doc(templateId).valueChanges().pipe(
      map((eventTemplateJson) => {
        return eventTemplateJson as EventTemplate; // plainToClass(EventTemplate, eventTemplateJson);
      })
    );
  }

  /**
   * Returns all users of the specified account.
   *
   * @param accountId the account ID
   * @returns all users of the account, otherwise empty list
   */
  public getUsersOfAccount(accountId: string): Observable<User[]> {
    return this.afs.collection<User>('users', ref => ref.where('accountId', '==', accountId))
      .valueChanges().pipe(
        map((usersJson) => {
          return usersJson as User[]; // plainToClass(User, usersJson as object[]);
        })
      );
  }

  /**
   * Returns all users of the specified event.
   *
   * @param eventId the event ID
   * @returns all users of the event, otherwise empty list
   */
  public getUsersOfEvent(eventId: string): Observable<User[]> {
    return this.afs.collection<User>('users', ref => ref.where('eventIds', 'array-contains', eventId))
      .valueChanges().pipe(
        map((usersJson) => {
          return usersJson as User[]; // plainToClass(User, usersJson as object[]);
        })
      );
  }

  /**
   * Returns all users.
   *
   * @returns the found users, otherwise empty list
   */
  public getAllUsers(): Observable<User[]> {
    return this.afs.collection<User>('users', ref => ref.orderBy('lastname'))
      .valueChanges().pipe(
        map((usersJson) => {
          return usersJson as User[]; // plainToClass(User, usersJson as object[]);
        })
      );
  }

  /**
   * Returns a user.
   *
   * @param userId the user ID
   * @returns the found user, otherwise undefined
   */
  public getUser(userId: string): Observable<User | undefined> {
    return this.userCollection.doc(userId).valueChanges().pipe(
      map((userJson) => {
        return userJson as User; // plainToClass(User, userJson);
      })
    );
  }

  /**
   * Returns a user by looking for its email address.
   *
   * @param email the email address of the user
   * @returns the found user, otherwise undefined
   */
  public getUserByEmail(email: string): Observable<User | undefined> {
    return this.afs.collection<User>('users', ref => ref.where('email', '==', email))
      .valueChanges().pipe(
        map((usersJson) => {
          const users: User[] = usersJson as User[]; // plainToClass(User, usersJson as object[]);

          return users.length > 0 ? users[0] : undefined;
        })
      );
  }

  /**
   * Adds a user.
   *
   * @param user the user to add
   * @returns the added user
   */
  public async addUser(user: User): Promise<User> {
    if (!user.id) {
      user.id = this.afs.createId();
    }
    if (!user.createdAt) {
      user.createdAt = new Date().toISOString();
    }
    user.updatedAt = new Date().toISOString();

    await this.userCollection.doc(user.id).set(classToPlain(user) as User, { merge: true });

    return user;
  }

  /**
   * Updates a user.
   *
   * @param user the user to update
   * @returns the updated user
   */
  public async updateUser(user: User): Promise<User> {
    await this.userCollection.doc(user.id).set(classToPlain(user) as User, { merge: true });

    return user;
  }

  /**
   * Removes users.
   *
   * @param userIds the IDs of the users to remove
   */
  public async removeUsers(userIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const userId of userIds) {
      batch.delete(this.userCollection.doc(userId).ref);
    }

    await batch.commit();
  }
}
