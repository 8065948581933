import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/guard/auth-guard.service';
import { LoginGuard } from './shared/guard/login-guard.service';


const routes: Routes = [
  { path: '', redirectTo: 'reservation', pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [LoginGuard] },
  { path: 'reservation', loadChildren: () => import('./reservation/reservation.module').then(m => m.ReservationModule) },
  { path: 'order', loadChildren: () => import('./order/order.module').then(m => m.OrderModule) },
  { path: 'payment-method', loadChildren: () => import('./payment-method/payment-method.module').then(m => m.PaymentMethodPageModule),
    canActivate: [AuthGuard] },
  { path: 'price-plan', loadChildren: () => import('./price-plan/price-plan.module').then(m => m.PricePlanPageModule), canActivate: [AuthGuard] },
  { path: 'plan-subscription', loadChildren: () => import('./plan-subscription/plan-subscription.module').then(m => m.PlanSubscriptionPageModule),
    canActivate: [AuthGuard] },
  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserPageModule), canActivate: [AuthGuard] },
  { path: 'location', loadChildren: () => import('./location/location.module').then(m => m.LocationPageModule), canActivate: [AuthGuard] },
  { path: 'language', loadChildren: () => import('./language/language.module').then(m => m.LanguagePageModule), canActivate: [AuthGuard] },
  { path: 'catalog-menu-list',
    loadChildren: () => import('./catalog-menu-list/catalog-menu-list.module').then(m => m.CatalogMenuListPageModule),
    canActivate: [AuthGuard]
  },
  { path: 'catalog-modifier-list-list',
    loadChildren: () => import('./catalog-modifier-list-list/catalog-modifier-list-list.module').then(m => m.CatalogModifierListListPageModule),
    canActivate: [AuthGuard]
  },
  { path: 'catalog-allergen', loadChildren: () => import('./catalog-allergen/catalog-allergen.module').then(m => m.CatalogAllergenPageModule),
    canActivate: [AuthGuard] },
  { path: 'catalog-additive', loadChildren: () => import('./catalog-additive/catalog-additive.module').then(m => m.CatalogAdditivePageModule),
    canActivate: [AuthGuard] },
  { path: 'catalog-diet', loadChildren: () => import('./catalog-diet/catalog-diet.module').then(m => m.CatalogDietPageModule),
    canActivate: [AuthGuard] },
  { path: 'catalog-discount', loadChildren: () => import('./catalog-discount/catalog-discount.module').then(m => m.CatalogDiscountPageModule),
    canActivate: [AuthGuard] },
  { path: 'catalog-tax', loadChildren: () => import('./catalog-tax/catalog-tax.module').then(m => m.CatalogTaxPageModule), canActivate: [AuthGuard] },
  { path: 'publication', loadChildren: () => import('./publication/publication.module').then(m => m.PublicationPageModule), canActivate: [AuthGuard] },
  { path: 'import', loadChildren: () => import('./import/import.module').then(m => m.ImportPageModule), canActivate: [AuthGuard] },
  { path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
