import { Injectable } from '@angular/core';

import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';


/**
 * Class providing access methods for geo positions.
 */
@Injectable({
  providedIn: 'root'
})
export class GeoPositionAccess {

  /**
   * The default constructor.
   */
  constructor(
    private geolocation: Geolocation
  ) {
  }

  /**
   * Returns current position.
   *
   * @returns the nearby locations, e.g. [52.512167,13.390289]
   * @throws error if failed to retrieve position
   */
  public async getCurrentPosition(): Promise<number[]> {
    const geoResponse: Geoposition = await this.geolocation.getCurrentPosition({
      timeout: 2000
    });

    return [geoResponse.coords.latitude, geoResponse.coords.longitude];
  }
}
